import "./styles.css";
import { useLocale } from "../../utils/i18n";
import { enContent } from "./en";
import { zhHansContent } from "./zh-Hans";
import { zhHantContent } from "./zh-Hant";

export default function Privacy() {
  const { locale } = useLocale();
  return (
    <main className="background">
      {locale === "en"
        ? enContent
        : locale === "zh-Hant"
        ? zhHantContent
        : zhHansContent}
    </main>
  );
}
