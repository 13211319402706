import React from "react";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4A83FF",
          colorLink: "#4A83FF",
        },
      }}
    >
      <RouterProvider router={routes} />
    </ConfigProvider>
  );
};

export default App;
