import { useState } from "react";

export type LocaleKey = "zh-Hans" | "zh-Hant" | "en";
export const defaultLocale: LocaleKey = "zh-Hans";

export function normalizeLocale(language: string): LocaleKey | undefined {
  if (language.startsWith("zh")) {
    switch (language) {
      case "zh-HK":
      case "zh-MO":
      case "zh-TW":
        return "zh-Hant";
      default:
        return "zh-Hans";
    }
  } else if (language.startsWith("en")) {
    return "en";
  }
}

export function useLocale() {
  const [locale] = useState<LocaleKey>(() => {
    for (const language of navigator.languages) {
      const locale = normalizeLocale(language);
      if (locale) return locale;
    }
    return defaultLocale;
  });

  return {
    locale,
  };
}
