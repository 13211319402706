export const enContent = (
  <>
    <h1>Lincy Membership Auto-Renewal Service Agreement</h1>
    <h2>I. Acceptance and Modification of Service Terms</h2>
    <p>
      This Agreement is entered into by Guangzhou Xuesheng Technology Co., Ltd.
      and its affiliates (hereinafter referred to as the "Company") and all
      entities using Lincy services (including but not limited to individuals,
      teams, etc.) (hereinafter referred to as the "Users") regarding the
      automatic renewal and authorized deduction service (hereinafter referred
      to as the "Service") provided by Lincy for membership. This Agreement
      describes the rights and obligations between Lincy and its members
      regarding the use of the Service and related matters. "Member" refers to
      an individual or a single entity that enjoys various membership services
      provided by Lincy. This Agreement constitutes a prerequisite for members
      (whether individuals or entities) to use the Service provided by Lincy.
      Unless the member accepts the terms of this Agreement, the member shall
      have no right to use the Service. The member's choice to use the Service
      shall be deemed as consent to be bound by all the terms of this Agreement.
    </p>
    <h2>II. Service Description</h2>
    <p>
      2.1 The Service is launched to meet the needs of members for automatic
      renewal. On the premise that the member has activated this Service, it
      aims to prevent losses caused by the member's failure to renew in a timely
      manner due to negligence or other reasons. The member irrevocably
      authorizes Lincy to deduct the next billing cycle fee from the member's
      own recharge account, third-party payment account bound to the member's
      account, bank card, or communication account (hereinafter collectively
      referred to as the "Account") before and after the expiration of the
      member's validity period. The billing cycle includes but is not limited to
      monthly, quarterly, semi-annual, and annual (hereinafter referred to as
      the same). Members can choose the billing cycle displayed on the Lincy
      platform when purchasing membership services provided by Lincy. The
      premise for the realization of this service is that the member has bound
      their Lincy membership account to the above-mentioned Account and can
      successfully deduct from the said Account.
    </p>
    <p>
      2.2 Automatic renewal specifically refers to the deduction method by which
      Lincy collects the next billing cycle fee through the above-mentioned
      Account based on the premise of 2.1. Members shall ensure that Lincy can
      successfully deduct from the above-mentioned Account. Members shall be
      solely responsible for renewal failure due to insufficient deductible
      balance in the above-mentioned Account.
    </p>
    <p>
      2.3 When members enjoy this Service, they shall be bound by the Lincy User
      Service Agreement. This Agreement is a supplementary agreement to
      the Lincy User Service Agreement and is an integral part thereof. In case
      of any conflict between this Agreement and the Lincy User Service
      Agreement, this Agreement shall prevail. When a member uses this Service,
      the member's use shall be deemed as consent to the terms of service of
      this Service and various announcements issued by Lincy regarding this
      Service.
    </p>
    <p>
      2.4 The Service provided by Lincy to members is limited to the use of
      members on the Lincy platform. Any act of separating the Service provided
      by Lincy from the Lincy platform by illegal means such as malicious
      cracking does not fall within the scope of the Service agreed upon in this
      Agreement. The perpetrator shall be responsible for all legal consequences
      arising therefrom, and Lincy shall pursue the perpetrator's legal
      liability according to law.
    </p>
    <h2>III. Rights and Obligations of Both Parties</h2>
    <p>
      3.1 If Lincy deducts the next billing cycle fee through the member's
      above-mentioned Account, it shall automatically deduct the fee within 24
      hours before the expiration of each billing cycle after the member
      activates the automatic renewal service, and the payment shall be recorded
      in the member's payment record, and the member's validity period shall be
      extended accordingly. There may be a delay in the update of the automatic
      renewal status. The actual payment status of the automatic renewal
      agreement shall prevail. If the member does not cancel the automatic
      renewal function at least 24 hours before the expiration of the billing
      cycle, the service will be automatically renewed and deducted.
    </p>
    <p>
      3.2 If an error occurs during the deduction process, Lincy and the member
      shall cooperate closely to find out the cause, and each party shall bear
      the losses caused by its own fault. If the losses are caused by the
      unequal fault of both parties, both parties shall bear the corresponding
      responsibilities according to the degree of their fault. If the losses are
      caused by the joint fault of both parties but the respective fault cannot
      be distinguished, both parties shall bear the responsibility equally.
    </p>
    <p>
      3.3 Lincy may change or modify the relevant service content, rules, and
      terms of this Agreement according to its business development or technical
      upgrades. Lincy will inform users of the relevant changes or modifications
      through public announcements on relevant pages of Lincy, but it is not
      obligated to make separate notifications. If the member does not agree
      with the modification of this Agreement, they can cancel the Service
      already obtained and stop using it. If the member continues to use the
      service provided by Lincy, it shall be deemed that the member has accepted
      all modifications to this Agreement.
    </p>
    <p>
      3.4 Lincy will notify users five days before automatic renewal in a
      prominent manner such as SMS in accordance with relevant regulatory
      requirements. At the same time, members can choose whether to cancel the
      service within the required time. If the member chooses not to cancel, it
      shall be deemed that the member agrees that Lincy will attempt to deduct
      the fee according to certain rules. Once the deduction is successful,
      Lincy will activate the membership service for the next billing cycle for
      the member.
    </p>
    <p>
      3.5 If the price of the relevant membership service of Lincy is adjusted
      at/before the automatic renewal deduction, the current valid price shall
      prevail.
    </p>
    <h2>IV. Activation of Automatic Renewal Service</h2>
    <p>
      4.1 If you activate this Service from Lincy's Android mobile client/Apple
      mobile client/PC client or web page/mobile web page, you are deemed to
      have authorized Lincy to deduct the next billing cycle service fee from
      your authorized and bound payment channels including but not limited to
      WeChat, Alipay, Telecom Operator Collection, Tenpay, and ApplePay one day
      before or on the day of the expiration of your relevant membership
      service. The billing cycle includes but is not limited to monthly,
      quarterly, semi-annual, and annual.
    </p>
    <p>
      4.2 For the selected payment channel, you are obligated to ensure that you
      pay attention to it regularly and ensure that there is sufficient balance
      in the account of the payment method to meet the needs of automatic
      renewal. If the automatic renewal fails due to defects in the
      aforementioned reasons (including but not limited to: insufficient
      balance, balance not enough to meet the user's choice of multiple
      automatic renewal services), the relevant membership service will not be
      able to continue.
    </p>
    <h2>V. Validity Period of Automatic Renewal Service</h2>
    <p>
      5.1 Unless otherwise specified by Lincy, the automatic renewal service you
      obtain is valid for a long term.
    </p>
    <p>
      5.2 Lincy will automatically renew your chosen Lincy service period before
      its expiration. If you need to make changes, please follow the method in
      Article 6 below. Otherwise, Lincy has the right to deem that you have
      entrusted Lincy to renew the corresponding service fee through your
      default payment method.
    </p>
    <p>
      5.3 You can choose whether to cancel this service. If you choose not to
      cancel, you are deemed to agree that Lincy will attempt to deduct the fee
      according to this Agreement. Once the deduction is successful, Lincy will
      activate the automatic renewal service for the next billing cycle for you.
      If the price of the Lincy package service is adjusted at/before the
      automatic renewal, the current valid price shall prevail.
    </p>
    <p>
      5.4 Lincy hereby reminds you: When you choose to use the automatic renewal
      service, Lincy will deem that you have subscribed to the relevant paid
      services of Lincy for the fees paid by you through the automatic renewal
      service entrusted to Lincy in your default payment method. Therefore,
      Lincy will not refund the fees you have paid in any way.
    </p>
    <h2>VI. Unsubscribing from Automatic Renewal Service</h2>
    <p>
      6.1 You can choose to unsubscribe from this service. You can turn off the
      automatic renewal membership service through the following cancellation
      process:
    </p>
    <p>
      [Mobile Client] Visit the Lincy Personal Center, log in to your account,
      visit the Membership Center, and click "Cancel Automatic Renewal".
    </p>
    <p>
      6.2 After the automatic renewal package is successfully canceled, it will
      not affect your current valid membership rights. The membership rights
      will no longer be renewed after they expire.
    </p>
    <p>
      6.3 The instructions you have entrusted Lincy to automatically renew and
      deduct before you choose to terminate this service are still valid, and
      Lincy will not refund the fees deducted based on these instructions.
    </p>
    <h2>VII. Validity Period and Termination of the Agreement</h2>
    <p>
      7.1 This Agreement shall take effect from the time the member chooses to
      accept or use this service until the member terminates this
      service/cancels their membership.
    </p>
    <p>
      7.2 The member has the right to terminate this service at any time in the
      member account settings. After the termination of this service, Lincy will
      stop providing this service to the member.
    </p>
    <p>
      7.3 The instructions that the member has entrusted to Lincy for automatic
      renewal and deduction before choosing to terminate this service are still
      valid, and Lincy will not refund the fees deducted based on these
      instructions. The member shall bear the relevant responsibilities.
    </p>
    <h2>VIII. Liability for Breach of Contract</h2>
    <p>
      8.1 If one party breaches the contract, the non-breaching party has the
      right to obtain compensation through the dispute resolution method agreed
      upon by both parties.
    </p>
    <p>
      8.2 Lincy and the member shall resolve any disputes arising from the
      performance of this Agreement through friendly negotiation. If the
      negotiation fails, either party has the right to submit the dispute to the
      People's Court where the Company is located for litigation. The
      interpretation, validity, and enforcement of this Agreement shall be
      governed by the laws of the People's Republic of China.
    </p>
  </>
);
