export const zhHansContent = (
  <>
    <h1>语有灵犀会员自动续费服务协议</h1>
    <h2>一、服务条款的接受与修改</h2>
    <p>
      本协议系由广州学晟科技有限公司及其关联公司（以下简称&ldquo;公司&rdquo;）与所有使用语有灵犀服务的主体（包括但不限于个人、团队等）（以下简称&ldquo;用户&rdquo;）对关于会员使用语有灵犀提供的自动续费委托扣款服务（下称&ldquo;本服务&rdquo;）所订立的协议。本协议描述语有灵犀与会员之间关于本服务的使用及相关方面的权利义务。&ldquo;会员&rdquo;是指享受语有灵犀提供的各类会员服务的个人或单一实体。&nbsp;
      <strong>
        本协议构成会员（无论个人或者单位）使用语有灵犀所提供的本服务之先决条件，除非会员接受本协议条款，否则会员无权使用本服务，会员选择使用本服务行为将视为同意接受本协议各项条款的约束。
      </strong>
    </p>
    <h2>二、服务说明</h2>
    <p>
      2.1
      本服务是出于会员对于自动续费的需求，在会员已开通本服务的前提下，避免会员因疏忽或其他原因导致未能及时续费造成损失而推出的服务，会员不可撤销地授权语有灵犀在会员有效期届满前后，从会员的自有充值账户、与会员账号绑定的第三方支付账户、银行卡、通信账户（以下统称&ldquo;账户&rdquo;）余额中代扣下一个计费周期的费用，计费周期包括但不限于月度、季度、半年、年度等（下同），会员可在购买语有灵犀提供的会员服务时根据语有灵犀平台上显示的计费周期自行选择。该服务实现的前提是会员已将其语有灵犀会员账号与上述账户绑定，且可成功从其上述账户中扣款。
    </p>
    <p>
      <strong>
        2.2
        自动续费具体指，基于2.1的前提，语有灵犀通过上述账户收取会员下一计费周期费用的扣费方式，会员需保证语有灵犀可以从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由会员自行承担责任。
      </strong>
    </p>
    <p>
      <strong>
        2.3
        会员在享受本服务时，应受《语有灵犀用户服务协议》约束，本协议为《语有灵犀用户服务协议》补充协议，是其不可分割的组成部分，如本规则与《语有灵犀用户服务协议》存在冲突的，以本规则为准；当会员使用本服务时，会员的使用行为视为其对本服务的服务条款以及语有灵犀针对本服务发出的各类公示的同意。
      </strong>
    </p>
    <p>
      2.4
      语有灵犀向会员提供的本服务均仅限于会员在语有灵犀平台使用，任何以恶意破解等非法手段将语有灵犀提供的本服务与语有灵犀平台分离的行为，均不属于本协议中约定的本服务。由此引起的一切法律后果由行为人负责，语有灵犀将依法追究行为人的法律责任。
    </p>
    <h2>三、双方的权利和义务</h2>
    <p>
      <strong>
        3.1
        语有灵犀通过会员上述账户扣除下一计费周期费用的，应在会员开通自动续费服务后，在每个计费周期到期前24小时自动划扣，并将款项记入会员支付记录，并同时相应延长会员有效期。
        自动续费状态更新可能会存在显示延时，以实际支付自动续费签约状态为准，如会员未在计费周期到期前至少24小时前取消自动续费功能，将会自动续订及扣款。
      </strong>
    </p>
    <p>
      <strong>
        3.2
        如在扣费过程出现差错，语有灵犀和会员应密切配合查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成损失的，由双方按过错的程度承担相应责任；因双方共同过错造成损失但无法区分各自过错的，由双方均摊责任。
      </strong>
    </p>
    <p>
      <strong>
        3.3
        语有灵犀可根据其业务开展或技术升级等情况变更或修改本协议的有关服务内容、规则及条款，语有灵犀将通过在语有灵犀相关页面公示等方式告知用户相关变更或修改内容，但无义务另行做个别通知。如果会员不同意本协议的修改，可以取消已经获取的本服务并停止使用；如果会员继续使用语有灵犀提供的服务，则视为会员已经接受本协议的全部修改。
      </strong>
    </p>
    <p>
      <strong>
        3.4
        语有灵犀会按照相关法规要求在自动续费前五日以短信等显著方式通知用户，同时本服务由会员自主选择是否在要求的时间内取消，若会员选择不取消，则视为会员同意语有灵犀按照一定规则进行扣款尝试，一旦扣款成功，语有灵犀将为会员开通下一个计费周期的会员服务。
      </strong>
    </p>
    <p>
      <strong>
        3.5
        若在自动续费扣款时/之前，语有灵犀相关会员的服务价格发生调整，应以现时有效的价格为准。
      </strong>
    </p>
    <h2>四、自动续费服务的开通</h2>
    <p>
      <strong>
        4.1
        如您自语有灵犀相关的安卓移动客户端／苹果移动客户端／PC客户端或网页端／移动网页端开通本服务，则视为您授权语有灵犀在您的相关会员服务到期前一天或者到期当天，从您授权绑定的包括但不限于：微信、支付宝、电信运营商代收、财付通、ApplePay等支付渠道中代扣下一个计费周期服务费，计费周期包括但不限于月度、季度、半年、年度等。
      </strong>
    </p>
    <p>
      <strong>
        4.2
        对于所选择的支付渠道，您有义务确保定期关注并确保其于该支付方式的账号项下有充足的余额用于满足自动续费的需求。如因前述原因瑕疵（包括但不限于：余额不足、余额不足以满足用户选择多个自动续费服务）而致使无法成功自动续费的，相关会员服务将无法继续。
      </strong>
    </p>
    <h2>五、自动续费服务有效期</h2>
    <p>5.1 除非语有灵犀另行指定，否则您获得的自动续费服务均为长期有效。</p>
    <p>
      5.2
      语有灵犀将于您所选择的语有灵犀服务期届满前进行自动续费，如您有变更，请按如下第6条的方式进行变更，否则语有灵犀有权视您委托语有灵犀通过您默认的支付方式续取相应的服务费用。
    </p>
    <p>
      <strong>
        5.3&nbsp;
        <u>
          本服务由您自主选择是否取消，若您选择不取消，则视为您同意语有灵犀按照本协议进行扣款尝试，一旦扣款成功，语有灵犀将为您开通下一个计费周期的自动续费服务。若在自动续费时/之前，语有灵犀包服务价格发生调整，应以现时有效的价格为准。
        </u>
      </strong>
    </p>
    <p>
      <strong>
        5.4&nbsp;
        <u>
          语有灵犀特别提醒您：当您选择使用自动续费服务后，通过自动续费服务委托语有灵犀在您默认的支付方式所支付的费用，语有灵犀将视您选择认购了语有灵犀的相关付费服务。因此，语有灵犀将不以任何方式对您所支付的费用予以退还。
        </u>
      </strong>
    </p>
    <h2>六、自动续费服务的退订</h2>
    <p>
      6.1 您可自主选择退订本服务。 通过如下解约流程，即可关闭自动续费会员服务：
    </p>
    <p>
      【移动客户端】访问语有灵犀个人中心登录账号，访问会员中心，点击取消自动续费。
    </p>
    <p>
      <strong>
        6.2
        自动续费套餐取消成功后，不影响您当期已生效的会员权益，会员权益到期后将不再续费。
      </strong>
    </p>
    <p>
      <strong>
        6.3
        您在选择终止本服务前已经委托语有灵犀自动续费扣款的指令仍然有效，语有灵犀对于基于该指令扣除的费用不予退还。
      </strong>
    </p>
    <h2>七、协议有效期限、终止</h2>
    <p>
      7.1
      本协议自会员选择接受或使用本服务后生效，直至会员终止本服务/注销会员资格时终止。
    </p>
    <p>
      7.2
      会员有权随时在会员账号设置中选择终止本服务，终止本服务后，语有灵犀将停止向会员提供本服务。
    </p>
    <p>
      <strong>
        7.3
        会员在选择终止本服务前已经委托语有灵犀自动续费扣款的指令仍然有效，语有灵犀对于基于该指令扣除的费用不予退还，由会员承担相关责任。
      </strong>
    </p>
    <h2>八、违约责任</h2>
    <p>8.1 如果一方违约，守约方有权通过双方约定的争议解决方式获得补偿。</p>
    <p>
      8.2
      语有灵犀与会员应通过友好协商解决本协议履行过程中产生的争议，经协商无法解决的，
      <strong>任何一方均有权将争议提交至公司经营地的人民法院诉讼解决。</strong>
      本协议的解释、效力和执行等有关问题均适用中华人民共和国法律。
    </p>
  </>
);
