import { Flex, Space, Layout } from "antd";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const { Footer } = Layout;

export default function CommonFooter() {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <Footer>
      <Flex align="center" justify="center">
        <Space align="center" direction="vertical" style={{ fontSize: 16 }}>
          <span>©{currentYear} 广州学晟科技有限公司</span>
          <Flex gap={16} wrap="wrap" justify="center">
            <span>地址：广东省广州市番禺区大学城中心北大街66号创新大厦2楼</span>
            <span>联系电话：400-990-9669</span>
            <span>邮箱：service@pyhot.cn</span>
          </Flex>
          <Flex gap={16} wrap="wrap" justify="center">
            <span>粤公网安备44011302004362号</span>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              粤ICP备2022002676号-3
            </a>
            <a
              href="https://beian.cac.gov.cn/#/home"
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              网信算备440106594358901240015号
            </a>
            <Link target="_blank" to="/privacy" style={{ color: "inherit" }}>
              隐私政策
            </Link>
          </Flex>
        </Space>
      </Flex>
    </Footer>
  );
}
