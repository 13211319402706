import { ComponentPropsWithoutRef, MouseEvent, useState } from 'react'
import OpenInSafariHint from './open-in-safari-hint'
import { isWechat } from '../../utils/wechat'

export default function AppDownloadLink({
  children,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  const [isHintOpen, setHintOpen] = useState(false)

  function onClick(e: MouseEvent) {
    if (!isWechat()) return

    e.preventDefault()
    e.stopPropagation()
    setHintOpen(true)
  }

  return (
    <>
      <a {...props} onClick={onClick}>
        {children}
      </a>
      
      <OpenInSafariHint open={isHintOpen} onOpenChange={setHintOpen} />
    </>
  )
}
