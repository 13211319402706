import "./styles.css";
import logo from "./logo.svg";
import { Layout } from "antd";
import CommonFooter from "../../components/CommonFooter";

export default function Home() {
  return (
    <Layout>
      <Layout.Content>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              对话 <code>AI</code>，对话世界
            </p>
          </header>
        </div>
      </Layout.Content>
      <CommonFooter />
    </Layout>
  );
}
