export const enContent = (
  <>
    <h1>User Service Agreement of Lincy</h1>
    <p>
      Welcome to use the services provided by Guangzhou Xuesheng Technology Co.,
      Ltd. The user service agreement of Lincy (hereinafter referred to as "this
      agreement") is an agreement entered into by the user (hereinafter referred
      to as "you" or "user") and Guangzhou Xuesheng Technology Co., Ltd. and its
      affiliates (hereinafter referred to as Lincy) regarding the user's use of
      the services provided by Lincy.
    </p>
    <h2>[Special Note]</h2>
    <ol>
      <li>
        Please carefully read all the terms of this agreement. Before
        registering as a member, please carefully read the examination and
        question bank introduction page and all the terms of this agreement, and
        confirm that you have fully understood the teaching syllabus of Lincy
        and the provisions of this agreement. After registration.
      </li>
      <li>
        Minors should read this agreement under the accompaniment and guidance
        of their guardians and obtain the consent of their guardians before
        using the services under this agreement. If you do not have the civil
        capacity corresponding to your behavior as mentioned above, then you and
        your guardian shall bear all consequences resulting therefrom in
        accordance with the law.
      </li>
      <li>
        Please carefully review and fully understand the content of each clause
        of this agreement and choose to accept or not accept this agreement.{" "}
        <strong>
          <u>
            In particular, clauses on exemption or limitation of liability,
            legal application and dispute resolution clauses should be read
            carefully. Your acts of registering, logging in, and using on Lincy
            will be deemed as acceptance of this agreement and deemed as your
            agreement to be bound by the various clauses of this agreement. This
            agreement will constitute a legally binding document directly
            between you and Lincy.
          </u>
        </strong>
      </li>
      <li>
        Please confirm that in accordance with Chinese laws and regulations and
        the laws of your place of residence and the laws under which you
        voluntarily or should be subject to jurisdiction, you have the right to
        sign and agree to be bound by this agreement. If you represent your
        employer or unit, you confirm and guarantee that you have obtained
        sufficient authorization to sign this agreement.
      </li>
    </ol>
    <h2>I. Service Notice</h2>
    <ol>
      <li>
        When you use the "Lincy" software service, you can obtain the client
        application program or access the relevant website of Lincy through
        pre-installation, downloading from a third party authorized by the
        company, etc. If you do not obtain the "Lincy" software from the company
        or a third party authorized by the company, the company does not
        guarantee that the unofficial version of the "Lincy" software can be
        used normally, and you are not responsible for any losses you suffer.
      </li>
      <li>
        The company develops different application software versions for
        different terminal devices. You should obtain, download, and install the
        appropriate version (if any) according to the actual device status.
      </li>
      <li>
        You can use the "Lincy" software and related services or update the
        "Lincy" version as needed. If you no longer need to use the "Lincy"
        software and related services, you can also uninstall the corresponding
        application software on your own.
      </li>
      <li>
        Unless you have the company's express prior written authorization, you
        may not access or use the "Lincy" software and related services in any
        unauthorized form including but not limited to adaptation, copying,
        dissemination, vertical search, mirroring or trading.
      </li>
      <li>
        In order to improve the user experience, Guangzhou Xuesheng Technology
        Co., Ltd. will provide updates from time to time. In order to ensure the
        security of the software and services and the consistency in function
        and security, Guangzhou Xuesheng Technology Co., Ltd. has the right to
        update the software without notifying you in particular.
      </li>
    </ol>
    <h2>II. Paid Services and Instructions</h2>
    <ol>
      <li>
        Some of the services provided by Lincy are paid services. When you use
        paid services, you need to pay a certain fee to Lincy. For paid
        services, Lincy will give you clear prompts. The completion of the
        payment behavior is subject to the confirmation notice of "payment
        completed" generated by the bank or third-party payment platform. Only
        when you confirm according to the prompts that you are willing to pay
        the relevant fees can you use the paid service. If you refuse to pay the
        relevant fees, Lincy has the right not to provide you with the paid
        service.
      </li>
      <li>
        You confirm that after completing the payment behavior for the paid
        service of Lincy, it will be deemed that you have accepted and started
        using the paid service of Lincy.{" "}
        <strong>
          <u>
            Therefore, any paid or value-added service you have purchased on
            Lincy cannot be returned (that is, exchanged for legal tender) or
            exchanged for other services for any reason.
          </u>
        </strong>
      </li>
      <li>
        You understand that Lincy only provides technical services related to
        Lincy services. In addition, equipment related to relevant network
        services (such as personal computers, mobile phones, and other devices
        related to accessing the Internet and mobile networks) and the required
        expenses (such as telephone charges for accessing the Internet and
        Internet access fees, mobile phone charges for using mobile networks,
        etc.) are all borne by you.
      </li>
      <li>
        <u>
          If Lincy is unable to provide the paid service after you purchase the
          paid service due to your own reasons, you shall be responsible for any
          losses or liabilities. Lincy shall not be liable, including but not
          limited to:
        </u>
        <ol>
          <li>
            <u>Due to the loss, ban or freeze of your account;</u>
          </li>
          <li>
            <u>
              Property losses caused by you disclosing your password to others;
            </u>
          </li>
          <li>
            <u>
              Any losses or liabilities caused by the account of the third-party
              payment institution you bind;
            </u>
          </li>
          <li>
            <u>
              Property losses caused by other users' intentional or gross
              negligence or violation of the law.
            </u>
          </li>
        </ol>
      </li>
      <li>
        If your payment is inaccurate due to the malfunction of Lincy's payment
        system, under the condition that the platform is restored, there are
        valid data and you provide legal and valid vouchers, Lincy will take
        measures according to your payment situation:
        <ol>
          <li>
            Due to the malfunction of the recharge system, if the product amount
            is less than your actual payment amount, the platform will make up
            the difference;
          </li>
          <li>
            Due to the malfunction of the recharge system, if the product amount
            is greater than your actual payment amount, the platform has the
            right to recover the difference.
          </li>
        </ol>
      </li>
      <li>
        This course supports you to use a third-party channel (such as a bank,
        Huabei) for installment payment. Lincy only provides you with the
        convenience of a third-party payment channel. If you choose to pay in
        installments, you should carefully consider your own economic situation
        and sign a relevant agreement with the third party separately, and bear
        the repayment liability and the liability for breach of contract for
        overdue repayment according to the agreement. If Lincy incurs interest
        losses due to refunds from students on installment plans to Lincy, Lincy
        has the right to deduct it from the amount to be refunded.
      </li>
      <li>
        After you pay successfully, you can apply to Lincy for a value-added tax
        invoice within 15 days after payment. Lincy will issue a value-added tax
        invoice within 15 working days after receiving the application.
      </li>
    </ol>
    <h2>III. Account Registration</h2>
    <ol>
      <li>
        You need to register an account in Lincy products and set up the account
        according to the requirements publicized by Lincy products before you
        can use this account to log in and use Lincy related products and
        services. Lincy will deliver the examination questions and information
        services corresponding to the examination products you purchased at one
        time after confirming the receipt of all receivable fees. You can
        arrange your study according to your own needs.
      </li>
      <li>
        When registering a Lincy account, you should fill in or provide
        materials and information according to the prompts and requirements
        publicized by Lincy products, and ensure the authenticity, correctness
        and integrity of the user identity and information, and ensure that the
        account name, avatar, personal profile and other account information
        materials filled in, logged in and used should abide by seven bottom
        lines such as laws and regulations, socialist system, national
        interests, legitimate rights and interests of citizens, public order,
        social moral fashion and information authenticity, and no bad
        information shall appear in the account information; if the materials
        change, you should change them in time. If you violate the above
        registration regulations, you shall bear the legal consequences, losses
        and liabilities resulting therefrom.
      </li>
      <li>
        The registration of Lincy accounts can use the associated authorization
        of third-party platform accounts. After your consent and confirmation,
        we will obtain the name and avatar of your third-party account and other
        information for registration. You should ensure that the information of
        the third-party account authorized by this association complies with the
        provisions of clause [3.2] of this agreement. Otherwise, the legal
        consequences, losses and liabilities resulting therefrom shall be borne
        by you.
      </li>
    </ol>
    <h2>IV. Account Usage</h2>
    <ol>
      <li>
        The ownership of Lincy accounts belongs to Guangzhou Xuesheng Technology
        Co., Ltd. After you complete the application registration procedures,
        you will obtain the right to use Lincy accounts. This right of use only
        belongs to the initial registrant and is prohibited from being given as
        a gift, borrowed, rented, transferred or sold. Lincy has the right to
        take back your account due to business needs.
      </li>
      <li>
        After you complete the registration as required by Lincy, you will get
        an account and password (if any). You should properly keep this account
        and password (if any) and be legally responsible for all activities and
        events related to this account. Lincy shall not be liable for any
        economic losses caused by the illegal use or possession of the account
        and password (if any) by others due to hacker behavior or your negligent
        custody.
      </li>
      <li>
        You should understand that when the account and password match, Lincy
        cannot distinguish illegal or unauthorized use of your account and
        password by others. Therefore, Lincy shall not be liable for any act of
        logging in and using Lincy products and services using your account and
        password.
      </li>
      <li>
        Lincy has the right, according to its own judgment, to refuse or limit
        account usage or cancel the account when it suspects that the account is
        being improperly used, and at the same time terminate this agreement.
        Except for paid services with a usage period, if you do not log in to
        Lincy products with your account for 180 consecutive days, Lincy has the
        right, according to its own judgment, to cancel the account without
        sending you notice in advance.
      </li>
      <li>
        You shall not use Lincy services to engage in any of the following
        things or activities:
        <ol>
          <li>Opposing the basic principles determined by the constitution;</li>
          <li>
            Endangering national unity, sovereignty and territorial integrity;
          </li>
          <li>
            Disclosing state secrets, endangering national security or
            undermining national honor and interests;
          </li>
          <li>
            Inciting ethnic hatred, ethnic discrimination, undermining ethnic
            unity, or infringing on ethnic customs and habits;
          </li>
          <li>Promoting cults and superstitions;</li>
          <li>Disrupting social order and undermining social stability;</li>
          <li>
            Inducing minors to commit crimes and rendering violence,
            pornography, gambling and terrorist activities;
          </li>
          <li>
            Insulting or slandering others, infringing on the personal
            reputation, privacy, intellectual property rights and other
            legitimate rights and interests of citizens;
          </li>
          <li>
            Endangering social morality and undermining the excellent
            traditional culture of the nation;
          </li>
          <li>
            Other contents prohibited by relevant laws, administrative
            regulations and state regulations.
          </li>
        </ol>
      </li>
      <li>
        If the information and content you upload, publish or transmit contain
        information or content that violates laws and regulations as mentioned
        above, or infringes the legitimate rights and interests of any third
        party, you will directly bear all the adverse consequences resulting
        therefrom. If Lincy suffers adverse consequences therefrom, you shall be
        responsible for eliminating the impact and compensating Lincy for all
        losses resulting therefrom, including but not limited to property damage
        compensation, reputation damage compensation, lawyer fees,
        transportation fees and other reasonable expenses incurred for rights
        protection.
      </li>
      <li>
        You agree to abide by the law and the provisions of this agreement, use
        Lincy in good faith, and ensure that:
        <ol>
          <li>
            You will not obtain or use the information of other users of Lincy
            in an illegal way;
          </li>
          <li>
            You will not attack, destroy or change part or all of Lincy services
            or interfere with its operation by technical means;
          </li>
          <li>
            You will not reverse engineer, decompile, disassemble or rewrite any
            program running on Lincy;
          </li>
          <li>
            You will not use the website name, company name, trademark, web page
            layout or content of Lincy, or the information and materials of
            intellectual property or rights enjoyed by Guangzhou Xuesheng
            Technology Co., Ltd. (and its affiliates) or Lincy without
            permission;
          </li>
          <li>
            You will not use Lincy for commercial purposes, and will not make
            any commercial use, copy, copy, sell, investigate or advertise any
            part or all of Lincy, and will not use Lincy for any other
            commercial purposes or commercial activities, except for specific
            commercial services that Lincy has separately signed an agreement
            with you or that Lincy has separately designated for your use.
          </li>
        </ol>
      </li>
      <p>
        If you violate the above agreement, Lincy may report your records of
        violating the law or infringing the rights or interests of third parties
        to administrative departments or judicial organs.
      </p>
      <li>
        For the usage rules and instructions of some specific services provided
        by Lincy, any statements, notifications, warnings and other contents
        made by Lincy through various means (including but not limited to web
        page announcements, system notifications, private messages, SMS
        reminders, etc.) are regarded as part of this agreement.{" "}
        <strong>
          <u>
            If you use such services, it is deemed that you agree to such
            statements, notifications and warning contents.
          </u>
        </strong>
      </li>
    </ol>
    <h2>V. Account Cancellation</h2>
    <ol>
      <li>
        When you complete all the cancellation procedures, it means that you
        have fully read, understood and accepted all the contents of the account
        cancellation clause.{" "}
        <strong>
          <u>
            If you have any objections to any condition or agreement in this
            clause, please immediately stop the account cancellation procedure.
          </u>
        </strong>
      </li>
      <li>
        After you confirm the cancellation of the Lincy account, you will no
        longer be able to use this Lincy account, nor will you be able to
        retrieve any content or information related to the Lincy account and the
        account. Lincy cannot help you restore such services and will not make
        any compensation. Such service content or information includes but is
        not limited to:
        <ol>
          <li>
            Account information, virtual assets, membership rights, purchased
            products;
          </li>
          <li>
            The relationship of binding or authorization with a third party;
          </li>
          <li>
            Personal data and historical information of this Lincy account
            (including but not limited to user name, user avatar, shopping
            records, follow information, learning data, certificates, etc.);
          </li>
          <li>
            Coupons, points, qualifications, orders and other cards and coupons
            you have obtained.
          </li>
        </ol>
      </li>
      <p>
        <strong>
          <u>
            You know and understand that according to relevant laws and
            regulations, your relevant transaction records must be kept in the
            background of Lincy for 3 years or even longer.
          </u>
        </strong>
      </p>
      <li>
        If you insist on canceling the account, your account needs to meet the
        following conditions at the same time:
        <ol>
          <li>
            In the recent month, there is no sensitive operation such as
            changing the password or changing the binding information of the
            account;
          </li>
          <li>
            Voluntarily give up the assets and virtual rights of the account in
            the Lincy system;
          </li>
          <li>There are no unfinished orders and services in the account;</li>
          <li>
            There is no outstanding dispute in the account, including but not
            limited to complaints, reports, lawsuits, arbitrations,
            investigations by state authorities with power.
          </li>
        </ol>
      </li>
      <li>
        During the account cancellation period, if your Lincy account is
        involved in dispute disputes, including but not limited to complaints,
        reports, lawsuits, arbitrations, investigations by state authorities
        with power, Lincy has the right to terminate the cancellation of this
        Lincy account on its own without obtaining your consent separately.
      </li>
      <li>
        Canceling this Lincy account does not mean that the account behavior and
        related responsibilities before the cancellation of this Lincy account
        are exempted or reduced.
      </li>
    </ol>
    <h2>VI. Personal Information and Privacy Clause</h2>
    <ol>
      <li>
        Lincy will collect, store, use and process user personal information in
        compliance with relevant laws and regulations, and collect and use
        personal information in accordance with the principles of legality,
        propriety and necessity.
      </li>
      <li>
        Lincy may need to collect personal information that can identify the
        user's identity so that it can contact the user when necessary or
        provide the user with a better user experience. "Personal information"
        includes but is not limited to the user's name, gender, age, date of
        birth, contact number, ID number, address, education status, company
        situation, industry, hobbies, location information, personal
        description, mobile phone software and hardware information, mobile
        phone identification code, etc.
      </li>
      <li>
        Lincy promises not to disclose the user's personal information that
        should be kept confidential in accordance with the regulations of Lincy
        in an illegal way.
      </li>
      <li>
        You understand and agree that in addition to being reasonably necessary
        for providing or improving Lincy products and services, Lincy may use
        your personal information in the following circumstances:
        <ol>
          <li>
            In case of emergency, to safeguard the rights and interests of users
            and the public;
          </li>
          <li>
            To safeguard the copyright, trademark right, patent right and any
            other legal rights or interests of Lincy;
          </li>
          <li>
            When conducting promotions or lotteries, Lincy may share users'
            personal information with sponsors. In these cases, Lincy will give
            prompts before sending user information, and users can terminate the
            transmission process by clearly indicating that they do not
            participate in the activity;
          </li>
          <li>
            To match user information with third-party data for the purpose of
            obtaining third-party data;
          </li>
          <li>
            To use user data for statistics in order to describe Lincy's
            services to future partners, advertisers and other third parties and
            for other legitimate purposes.
          </li>
        </ol>
      </li>
    </ol>
    <h2>VII. Intellectual Property</h2>
    <ol>
      <li>
        Guangzhou Xuesheng Technology Co., Ltd. has corresponding rights to the
        products and services related to Lincy and the objects protected by
        intellectual property laws or other laws contained in the software used
        by Lincy. The overall content of Lincy products and services and any
        part thereof (including but not limited to website design, programs,
        codes, design patterns and other patterns, product and service names)
        are copyrighted, trademarked and other intellectual property rights
        owned by Lincy. No one may use, copy or use for other purposes.
      </li>
      <li>
        The text, pictures, audio, video and other content transmitted through
        Lincy products are protected by copyright law, trademark law, patent law
        or other laws; without the written authorization and license of the
        relevant right holders, you may not use, modify, rent, sell, spread,
        derive other works or make any other use or disposition on your own or
        permit third parties.
      </li>
      <li>
        Lincy has intellectual property or corresponding rights to its
        proprietary content, original content and other content obtained through
        authorization. Without the written permission of Lincy, no unit or
        individual may privately reprint, spread and provide the services
        provided by Lincy or implement any other act that infringes on the
        intellectual property rights of Lincy. Otherwise, Lincy will investigate
        relevant legal responsibilities according to law.
      </li>
      <li>
        In order to promote the sharing and dissemination of knowledge, you
        agree to grant Lincy a free, irrevocable, non-exclusive license to use
        all the content (including but not limited to messages, comments and
        notes) published on Lincy products. Lincy has the right to use this
        content on various forms of products and services of Lincy, including
        but not limited to applications already or to be released by Lincy or
        other Internet products.
      </li>
      <li>
        The content you upload or publish on Lincy products should ensure that
        you are the copyright owner or have obtained legal authorization, and
        this content will not infringe the legitimate rights and interests of
        any third party. If a third party raises an objection regarding
        copyright, Lincy has the right to delete the relevant content according
        to the actual situation, and has the right to investigate the user's
        legal responsibility. If the user causes losses to Lincy or any third
        party, the user shall be responsible for full compensation.
      </li>
      <li>
        Lincy has the right to review the content you publish and has the right
        to process infringing information according to relevant evidence and in
        combination with laws and regulations such as the "Tort Liability Law"
        and the "Regulations on the Protection of the Right to Disseminate
        Information on the Internet" and the principles publicly disclosed by
        Lincy.
      </li>
    </ol>
    <h2>
      VIII. Disclaimer (Please read the following content carefully and in
      detail)
    </h2>
    <ol>
      <li>
        Lincy provides products and services to users according to the "current
        status" of Lincy products and services. Lincy does not make any other
        express or implied warranties regarding Lincy products and/or their
        functions and services. Any products, services (including paid services)
        or other materials obtained through Lincy products may not meet your
        expectations; due to the personal characteristics and preferences of
        different users, there may be some content in Lincy products that makes
        you unhappy, disgusted or difficult to accept; Lincy does not bear any
        responsibility to you for the foregoing circumstances.
      </li>
      <li>
        Any act you perform on Lincy is your personal act. Due to the vast
        amount of information, Lincy cannot review the ownership, legality,
        compliance, authenticity, scientific nature, integrity, validity, etc.
        of information or content.{" "}
        <strong>
          <u>
            Any disputes, responsibilities, or any direct, indirect, accidental,
            special damages arising from any information or content uploaded,
            stored, published, or spread by users are the legal responsibilities
            of users and Lincy does not bear any responsibility; if user
            behavior causes losses or adverse effects to Lincy, Lincy reserves
            the right to pursue the legal responsibilities of relevant users.
            Lincy is not liable for the deletion or failure to store the
            information posted by users.
          </u>
        </strong>
      </li>
      <li>
        <u>
          Under no circumstances will Lincy be liable for the inability to
          access Lincy products, interruption of products and services, delay,
          stagnation or error of information and data, and inability to provide
          or delay in providing services due to Internet connection failures,
          power failures, strikes, labor disputes, riots, uprisings, unrest,
          fires, floods, storms, explosions, force majeure, wars, government
          actions, orders of international and domestic courts, hacker attacks,
          Internet viruses, technical adjustments of network operators,
          temporary government control or any other reasons that Lincy cannot
          reasonably control.
        </u>
      </li>
      <li>
        <u>
          Regardless of whether it is foreseeable or not, regardless of the form
          of act or omission, Lincy is not liable for any special, indirect,
          punitive, sudden or any other damages (including but not limited to
          loss of profits or other available interests) caused for any reason.
          Except that Lincy intentionally or due to gross negligence damages the
          interests of users, in any case, the limit of compensation,
          compensation or any other liability that Lincy assumes to users will
          not exceed the amount of service fees that such users pay to Lincy for
          related services.
        </u>
      </li>
      <li>
        <u>
          Based on the open nature of the Internet, you are aware that when you
          upload information and content to the Internet, it may be copied,
          reprinted, modified or used by other organizations or individuals
          (including for illegal purposes). You must be fully aware of the
          existence of such risks. You agree that the above risks existing in
          the process of using Lincy products will be borne entirely by you, and
          Lincy will not be liable for any of this.
        </u>
      </li>
    </ol>
    <h2>IX. Service Suspension, Interruption and Termination</h2>
    <ol>
      <li>
        Lincy may choose to suspend, interrupt and terminate the platform
        service due to reasons such as its own business decisions. If such a
        situation occurs, Lincy will notify you in the form of an announcement.
      </li>
      <li>
        If the effective legal documents of the national administrative or
        judicial organs confirm that you have illegal or infringing acts, or if
        Lincy, according to its own judgment, believes that your behavior is
        suspected of violating the content of this agreement or the usage rules
        published by Lincy from time to time, or is suspected of violating
        relevant laws and regulations, then Lincy has the right to suspend,
        interrupt or terminate the provision of services to you without being
        liable to you or any third party for this.
      </li>
      <li>
        <u>
          You should understand and agree that in an emergency or special
          situation, Lincy may suspend or terminate product use and services
          without notice. When the use and service of Lincy products provided
          under this agreement (including paid services) is suspended or
          terminated, Lincy has the right to:
        </u>
        <ol>
          <li>Refuse users to log in to Lincy products and services;</li>
          <li>Delete user information;</li>
          <li>Delete the content purchased by users.</li>
        </ol>
      </li>
      <li>
        <u>
          You agree that if the paid product is terminated before the expiration
          due to the reasons of Lincy, Lincy only needs to return the
          corresponding price of the unexpired part according to the relevant
          product rules. Lincy does not need to bear other responsibilities or
          obligations. Except for the above circumstances, Lincy does not need
          to make compensation or bear any other responsibility due to the
          suspension or termination of the use and service of Lincy products
          provided under this agreement.
        </u>
      </li>
    </ol>
    <h2>X. Refund</h2>
    <ol>
      <li>
        <u>
          The nature of the goods of this course is digital knowledge products
          and video and audio products, which are special goods. You need to
          carefully consider before purchasing the products. Once the products
          are sold, there is no legal or agreed reason and no refund will be
          made.
        </u>
      </li>
      <li>
        If you apply for a refund within 7 days after successful payment due to
        legal or agreed reasons, the refund amount will be calculated according
        to the corresponding actual learning situation. After deducting the
        proportion of the used part, a refund can be initiated. The used part
        cannot be refunded. Once video and audio products are purchased, no
        refund will be given (if any).
      </li>
      <li>
        If you receive physical gifts from Lincy after purchasing this software
        product or service, you need to return the gift first when applying for
        a refund. The relevant expenses incurred due to returning the gift shall
        be borne by you. After Lincy inspects the returned gift and confirms
        that the packaging of the returned gift is exactly the same as when it
        was sent and there is no trace of use on the gift, Lincy will start to
        approve the refund. If you choose not to return the gift or the returned
        gift does not meet the aforementioned return conditions after
        inspection, the refund amount you can obtain will be deducted by the
        price of the gift.
      </li>
      <li>
        After the refund is successful, the points, vouchers, coupons, tickets,
        etc. (if any) you received on Lincy will be cancelled and cannot be used
        again.
      </li>
      <li>
        After the refund approval is completed, the refund amount will be
        returned to your payment account in the original way (for students who
        pay in installments through a third party, the refund amount will be
        directly returned to the third-party account, and the refund time is
        subject to the agreement of the third-party platform), and your
        corresponding course learning qualification will be cancelled, that is,
        you can no longer view and study the course.
      </li>
    </ol>
    <h2>XI. Applicable Law</h2>
    <p>
      When you use Lincy products and services, you should abide by the laws of
      the People's Republic of China, the laws of your place of residence, and
      the laws under which you voluntarily or should be subject to jurisdiction.
      The interpretation and performance of this agreement shall be in
      accordance with the laws of the People's Republic of China (for the
      purpose of this agreement, excluding Hong Kong, Macao and Taiwan regions).
    </p>
    <h2>XII. Dispute Resolution</h2>
    <p>
      Both you and Lincy agree that in case of any dispute arising from the
      interpretation or enforcement of this agreement, both parties shall first
      resolve it through friendly negotiation. If negotiation fails, either
      party may submit the dispute to the court where the operator of Lincy is
      located for litigation.
    </p>
    <h2>XIII. Other Agreements</h2>
    <ol>
      <li>
        If Lincy does not exercise, fails to exercise in a timely manner or does
        not fully exercise the rights enjoyed by this agreement or in accordance
        with the law, it shall not be deemed as waiving this right, nor does it
        affect Lincy's use of this right in the future.
      </li>
      <li>
        If this agreement (including any rules) is changed, Lincy will try to
        post a notice on Lincy products in advance as much as possible. However,
        you should understand and agree that Lincy may modify or change all or
        part of this agreement without prior notice, and you should also pay
        attention to whether this agreement has been modified or changed at any
        time. If this agreement is modified or changed and you cannot accept the
        modified or changed content, you should immediately stop using Lincy
        related products and services. If you continue to use Lincy related
        products and services, it indicates that you fully accept and are
        willing to abide by the modified or changed this agreement. Whether you
        stop using or continue to use Lincy related products and services, you
        promise not to claim compensation or compensation or raise other rights
        claims from Lincy due to the modification or change of this agreement.
      </li>
      <li>
        You confirm again that you have fully read and fully understood the
        above agreement. If you have any questions, you can contact Lincy
        through customer service.
      </li>
    </ol>
  </>
);
