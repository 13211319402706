import { createPortal } from 'react-dom'
import style from './open-in-safari-hint.module.css'

interface Props {
  open: boolean
  onOpenChange(open: boolean): void
}

export default function OpenInSafariHint({ open, onOpenChange }: Props) {
  if (!open) {
    return null
  }

  return createPortal(
    <div className={style.container} onClick={() => onOpenChange(false)}>
      <img src="/ms/open-in-safari.svg" alt='点击右上角 - 使用默认浏览器打开' />
    </div>,
    document.body
  )
}
