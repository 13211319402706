export const zhHantContent = (
  <>
    <h1>語有靈犀會員自動續費服務協議</h1>
    <h2>一、服務條款的接受與修改</h2>
    <p>
      本協議繫由廣州學晟科技有限公司及其關聯公司（以下簡稱&ldquo;公司&rdquo;）與所有使用語有靈犀服務的主體（包括但不限於個人、團隊等）（以下簡稱&ldquo;用戶&rdquo;）對關於會員使用語有靈犀提供的自動續費委托扣款服務（下稱&ldquo;本服務&rdquo;）所訂立的協議。本協議描述語有靈犀與會員之間關於本服務的使用及相關方麵的權利義務。&ldquo;會員&rdquo;是指享受語有靈犀提供的各類會員服務的個人或單一實體。&nbsp;
      <strong>
        本協議構成會員（無論個人或者單位）使用語有靈犀所提供的本服務之先決條件，除非會員接受本協議條款，否則會員無權使用本服務，會員選擇使用本服務行爲將視爲同意接受本協議各項條款的約束。
      </strong>
    </p>
    <h2>二、服務説明</h2>
    <p>
      2.1
      本服務是出於會員對於自動續費的需求，在會員已開通本服務的前提下，避免會員因疏忽或其他原因導緻未能及時續費造成損失而推出的服務，會員不可撤銷地授權語有靈犀在會員有效期屆滿前後，從會員的自有充值賬戶、與會員賬號綁定的第三方支付賬戶、銀行卡、通信賬戶（以下統稱&ldquo;賬戶&rdquo;）餘額中代扣下一個計費週期的費用，計費週期包括但不限於月度、季度、半年、年度等（下同），會員可在購買語有靈犀提供的會員服務時根據語有靈犀平颱上顯示的計費週期自行選擇。該服務實現的前提是會員已將其語有靈犀會員賬號與上述賬戶綁定，且可成功從其上述賬戶中扣款。
    </p>
    <p>
      <strong>
        2.2
        自動續費具體指，基於2.1的前提，語有靈犀通過上述賬戶收取會員下一計費週期費用的扣費方式，會員需保証語有靈犀可以從上述賬戶扣款成功，因上述賬戶中可扣款餘額不足導緻的續費失敗，由會員自行承擔責任。
      </strong>
    </p>
    <p>
      <strong>
        2.3
        會員在享受本服務時，應受《語有靈犀用戶服務協議》約束，本協議爲《語有靈犀用戶服務協議》補充協議，是其不可分割的組成部分，如本規則與《語有靈犀用戶服務協議》存在衝突的，以本規則爲準；當會員使用本服務時，會員的使用行爲視爲其對本服務的服務條款以及語有靈犀針對本服務髮出的各類公示的同意。
      </strong>
    </p>
    <p>
      2.4
      語有靈犀向會員提供的本服務均僅限於會員在語有靈犀平颱使用，任何以噁意破解等非法手段將語有靈犀提供的本服務與語有靈犀平颱分離的行爲，均不屬於本協議中約定的本服務。由此引起的一切法律後果由行爲人負責，語有靈犀將依法追究行爲人的法律責任。
    </p>
    <h2>三、雙方的權利和義務</h2>
    <p>
      <strong>
        3.1
        語有靈犀通過會員上述賬戶扣除下一計費週期費用的，應在會員開通自動續費服務後，在每個計費週期到期前24小時自動劃扣，並將款項記入會員支付記錄，並同時相應延長會員有效期。
        自動續費狀態更新可能會存在顯示延時，以實際支付自動續費籤約狀態爲準，如會員未在計費週期到期前至少24小時前取消自動續費功能，將會自動續訂及扣款。
      </strong>
    </p>
    <p>
      <strong>
        3.2
        如在扣費過程出現差錯，語有靈犀和會員應密切配合查明原因，各自承擔己方過錯造成的損失；若因雙方各自存在不均等過錯造成損失的，由雙方按過錯的程度承擔相應責任；因雙方共同過錯造成損失但無法區分各自過錯的，由雙方均攤責任。
      </strong>
    </p>
    <p>
      <strong>
        3.3
        語有靈犀可根據其業務開展或技術昇級等情況變更或修改本協議的有關服務內容、規則及條款，語有靈犀將通過在語有靈犀相關頁麵公示等方式告知用戶相關變更或修改內容，但無義務另行做個別通知。如果會員不同意本協議的修改，可以取消已經獲取的本服務並停止使用；如果會員繼續使用語有靈犀提供的服務，則視爲會員已經接受本協議的全部修改。
      </strong>
    </p>
    <p>
      <strong>
        3.4
        語有靈犀會按照相關法規要求在自動續費前五日以短信等顯著方式通知用戶，同時本服務由會員自主選擇是否在要求的時間內取消，若會員選擇不取消，則視爲會員同意語有靈犀按照一定規則進行扣款嚐試，一旦扣款成功，語有靈犀將爲會員開通下一個計費週期的會員服務。
      </strong>
    </p>
    <p>
      <strong>
        3.5
        若在自動續費扣款時/之前，語有靈犀相關會員的服務價格髮生調整，應以現時有效的價格爲準。
      </strong>
    </p>
    <h2>四、自動續費服務的開通</h2>
    <p>
      <strong>
        4.1
        如您自語有靈犀相關的安卓移動客戶端／蘋果移動客戶端／PC客戶端或網頁端／移動網頁端開通本服務，則視爲您授權語有靈犀在您的相關會員服務到期前一天或者到期當天，從您授權綁定的包括但不限於：微信、支付寶、電信運營商代收、財付通、ApplePay等支付渠道中代扣下一個計費週期服務費，計費週期包括但不限於月度、季度、半年、年度等。
      </strong>
    </p>
    <p>
      <strong>
        4.2
        對於所選擇的支付渠道，您有義務確保定期關注並確保其於該支付方式的賬號項下有充足的餘額用於滿足自動續費的需求。如因前述原因瑕疵（包括但不限於：餘額不足、餘額不足以滿足用戶選擇多個自動續費服務）而緻使無法成功自動續費的，相關會員服務將無法繼續。
      </strong>
    </p>
    <h2>五、自動續費服務有效期</h2>
    <p>5.1 除非語有靈犀另行指定，否則您獲得的自動續費服務均爲長期有效。</p>
    <p>
      5.2
      語有靈犀將於您所選擇的語有靈犀服務期屆滿前進行自動續費，如您有變更，請按如下第6條的方式進行變更，否則語有靈犀有權視您委托語有靈犀通過您默認的支付方式續取相應的服務費用。
    </p>
    <p>
      <strong>
        5.3&nbsp;
        <u>
          本服務由您自主選擇是否取消，若您選擇不取消，則視爲您同意語有靈犀按照本協議進行扣款嚐試，一旦扣款成功，語有靈犀將爲您開通下一個計費週期的自動續費服務。若在自動續費時/之前，語有靈犀包服務價格髮生調整，應以現時有效的價格爲準。
        </u>
      </strong>
    </p>
    <p>
      <strong>
        5.4&nbsp;
        <u>
          語有靈犀特別提醒您：當您選擇使用自動續費服務後，通過自動續費服務委托語有靈犀在您默認的支付方式所支付的費用，語有靈犀將視您選擇認購了語有靈犀的相關付費服務。因此，語有靈犀將不以任何方式對您所支付的費用予以退還。
        </u>
      </strong>
    </p>
    <h2>六、自動續費服務的退訂</h2>
    <p>
      6.1 您可自主選擇退訂本服務。 通過如下解約流程，即可關閉自動續費會員服務：
    </p>
    <p>
      【移動客戶端】訪問語有靈犀個人中心登錄賬號，訪問會員中心，點擊取消自動續費。
    </p>
    <p>
      <strong>
        6.2
        自動續費套餐取消成功後，不影響您當期已生效的會員權益，會員權益到期後將不再續費。
      </strong>
    </p>
    <p>
      <strong>
        6.3
        您在選擇終止本服務前已經委托語有靈犀自動續費扣款的指令仍然有效，語有靈犀對於基於該指令扣除的費用不予退還。
      </strong>
    </p>
    <h2>七、協議有效期限、終止</h2>
    <p>
      7.1
      本協議自會員選擇接受或使用本服務後生效，直至會員終止本服務/注銷會員資格時終止。
    </p>
    <p>
      7.2
      會員有權隨時在會員賬號設置中選擇終止本服務，終止本服務後，語有靈犀將停止向會員提供本服務。
    </p>
    <p>
      <strong>
        7.3
        會員在選擇終止本服務前已經委托語有靈犀自動續費扣款的指令仍然有效，語有靈犀對於基於該指令扣除的費用不予退還，由會員承擔相關責任。
      </strong>
    </p>
    <h2>八、違約責任</h2>
    <p>8.1 如果一方違約，守約方有權通過雙方約定的爭議解決方式獲得補償。</p>
    <p>
      8.2
      語有靈犀與會員應通過友好協商解決本協議履行過程中産生的爭議，經協商無法解決的，
      <strong>任何一方均有權將爭議提交至公司經營地的人民法院訴訟解決。</strong>
      本協議的解釋、效力和執行等有關問題均適用中華人民共和國法律。
    </p>
  </>
);
