import AutoRenewalPage from './pages/autoRenewal'
import DownloadPage from './pages/download'
import HomePage from './pages/home'
import Privacy from './pages/privacy'
import Terms from "./pages/terms"
import { createBrowserRouter } from 'react-router-dom'

export const routes = createBrowserRouter(
  [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/auto_renewal',
      element: <AutoRenewalPage />,
    },
    {
      path: '/download',
      element: <DownloadPage/>
    },
    {
      path: '/privacy',
      element: <Privacy />,
    },
    {
      path: '/terms',
      element: <Terms />
    }, {
      path: '*',
      element: <DownloadPage/>
    }
  ],
  {
    basename: '/ms',
  }
)
