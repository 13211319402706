export const zhHansContent = (
  <>
    <h1>《语有灵犀用户服务协议》</h1>
    <p>
      欢迎使用广州学晟科技有限公司提供的服务，语有灵犀用户服务协议（以下简称
      “本协议”）是用户（以下简称“您” 或“用户” ）与广州学晟科技有限公司
      及其关联方（以下简称语有灵犀）关于用户使用语有灵犀提供的服务所订立的协议。
    </p>
    <h2>【特别提示】</h2>
    <ol>
      <li>
        请您仔细阅读本协议全部条款，在注册成为会员前，请您仔细阅读考试及题库介绍页面及本协议全部条款，并确认您已经完全了解语有灵犀的教学大纲及本协议之规定。报名后。
      </li>
      <li>
        未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
      </li>
      <li>
        请您认真审阅并充分理解本协议各条款的内容，并选择接受或不接受本协议，
        <strong>
          <u>
            特别是免除或者限制责任的条款、法律适用和争议解决条款，您应当重点阅读。您在语有灵犀进行注册、登录、使用等行为将视为对本协议的接受，并视为您同意接受本协议的各项条款约束，本协议将构成您与语有灵犀之间直接有约束力的法律文件。
          </u>
        </strong>
      </li>
      <li>
        请您确认您依照中国法律法规及您所在地之法律及您自愿或应当接收其管辖之法律，有权签署并同意接受本协议的约束。如果您代表您的雇主或单位，您确认并保证您已获得签署本协议的充分授权。
      </li>
    </ol>
    <h2>一、服务须知</h2>
    <ol>
      <li>
        您使用“语有灵犀”软件服务，可以通过预装、公司授权的第三方下载等方式获取客户端应用程序或访问语有灵犀相关网站。若您并非从公司或公司授权的第三方获取“语有灵犀”软件，公司不保证非官方版本的“语有灵犀”软件可以正常使用，您因此遭受的损失与公司无关。
      </li>
      <li>
        公司为不同终端设别开发不同的应用程序软件版本，您应当根据实际设别状况获取、下载、安装合适的版本（如有）
      </li>
      <li>
        您可根据需要自行使用“语有灵犀” 软件及相关服务或更新“语有灵犀”
        版本，如您不再需要使用“语有灵犀”
        软件及相关服务，您也可自行卸载相应的应用程序软件。
      </li>
      <li>
        除非得到公司明示事先书面授权，您不得以任何形式对“语有灵犀”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
      </li>
      <li>
        为了改善用户体验，广州学晟科技有限公司将不定时提供更新，为了保证软件和服务的安全性，以及功能上和安全上的一致性，广州学晟科技有限公司有权不经向您特别通知而对软件进行更新
      </li>
    </ol>
    <h2>二、收费服务及说明</h2>
    <ol>
      <li>
        语有灵犀提供的部分服务为收费服务，您使用收费服务时需要向语有灵犀支付一定的费用。对于收费的服务，语有灵犀会给您明确的提示，支付行为的完成以银行或第三方支付平台生成
        “支付已完成”
        的确认通知为准。只有您根据提示确认其愿意支付相关费用，您才能使用该收费服务。如您拒绝支付相关费用，则语有灵犀有权不向您提供该收费服务。
      </li>
      <li>
        您确认，您在完成语有灵犀收费服务的支付行为后，将视为已接受并开始使用语有灵犀的收费服务，
        <strong>
          <u>
            因此您在语有灵犀已购买的任何收费或增值服务不能以任何理由退购（即退换成法定货币）或调换成其他服务
          </u>
        </strong>
        。
      </li>
      <li>
        您理解，语有灵犀仅提供与语有灵犀服务相关的技术服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网和移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费以及上网费、为使用移动网络而支付的手机费等）均由您自行负担。
      </li>
      <li>
        <u>
          因您自身原因导致的语有灵犀无法在您购买收费服务后提供该收费服务而产生任何损失或责任的，由您自行负责，语有灵犀不承担责任，包括但不限于：
        </u>
        <ol>
          <li>
            <u>因您的账户丢失、被封禁或冻结；</u>
          </li>
          <li>
            <u>您将密码告知他人导致的财产损失；</u>
          </li>
          <li>
            <u>因您绑定的第三方支付机构账户的原因导致的任何损失或责任；</u>
          </li>
          <li>
            <u>其他用户故意或者重大过失或违反法律导致的财产损失。</u>
          </li>
        </ol>
      </li>
      <li>
        若因语有灵犀支付系统自身故障原因造成您付款失实，在平台恢复、存有有效数据和您提供合法有效凭证的情况下，语有灵犀将根据您的付款情况作出变动措施：
        <ol>
          <li>
            因充值系统自身故障原因，造成产品金额小于您实际付款金额的，平台予以补其差额；
          </li>
          <li>
            因充值系统自身故障原因，造成产品金额大于您实际付款金额的，平台有权追回差额。
          </li>
        </ol>
      </li>
      <li>
        本课程支持您采用第三方渠道（如银行、花呗）分期方式付款，语有灵犀仅为您提供第三方支付通道的便利。如您采用分期方式付款的，应当切实考虑自身经济情况及与该第三方单独签署相关协议，并根据该协议的约定自行承担还款责任及逾期还款的违约责任。如因第三方分期学员退款给语有灵犀造成利息损失的，语有灵犀有权在应退款项中予以扣除。
      </li>
      <li>
        您付款成功后，可在付款后15
        日内向语有灵犀申请开具增值税发票，语有灵犀将于收到申请后
        15个工作日内开具增值税发票。
      </li>
    </ol>
    <h2>三、账号注册</h2>
    <ol>
      <li>
        您需要在语有灵犀产品中注册一个账号，并按照语有灵犀产品公示的要求进行账号设置，才可以使用该账号登录并使用语有灵犀相关产品和服务。语有灵犀将在确认收到全部应收费用后按您购买的考试产品一次性交付并获得相应考试试题及资讯服务，您可根据自身需求安排学习。
      </li>
      <li>
        您在注册语有灵犀账号时，应按照语有灵犀产品公示的提示及要求填写或提供资料、信息，并确保用户身份及信息的真实性、正确性及完整性，且保证填写、登录、使用的账号名称、头像、个人简介等账号信息资料应遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在账号信息中出现任何不良信息；如果资料发生变化，您应及时更改。如您违反上述注册规定，因此导致的法律后果、损失、责任将由您自行承担。
      </li>
      <li>
        语有灵犀账号的注册可使用第三方平台账号的关联授权，在经过您的同意并确认后，我们将获取您的带三方账号名称、头像等信息进行注册，您应当确保此关联授权的第三方账号信息符合本协议【
        3.2 条款】之规定，否则导致的法律后果、损失及责任将由您自行承担。
      </li>
    </ol>
    <h2>四、账号的使用</h2>
    <ol>
      <li>
        语有灵犀账号的所有权归广州学晟科技有限公司所有，您完成申请注册手续后，获得语有灵犀账号的使用权，该使用权仅属于初始注册人，禁止赠与、借用、租用、转让或者售卖。语有灵犀因经营需要，有权收回您的账号。
      </li>
      <li>
        您按照语有灵犀的要求完成注册后，将得到一个账号和密码（如有）。您应妥善保管该账号及密码（如有），并对该账号进行的所有活动及事件负法律责任。因黑客行为或您保管疏忽致使账号、密码（如有）被他人非法使用或占有而造成经济损失的，语有灵犀不承担任何责任。
      </li>
      <li>
        您应了解，在账号和密码匹配时，语有灵犀无法对非法或未经您授权使用您账号及密码的行为作出甄别，因此，语有灵犀对任何使用您账号和密码登录并使用语有灵犀产品及服务的行为不承担任何责任。
      </li>
      <li>
        语有灵犀有权根据自己的判定，在怀疑账号被不当使用时，拒绝或限制账号使用，或注销该账号，同时终止本协议。除有使用期限的付费服务以外，如您连续
        180
        天未以账号登录语有灵犀产品，则语有灵犀有权根据自己的判定，注销该账号且无需事先向您发送通知。
      </li>
      <li>
        您不得利用语有灵犀服务从事以下任一情形的事物或活动：
        <ol>
          <li>反对宪法确定的基本原则的；</li>
          <li>危害国家统一、主权和领土完整的；</li>
          <li>泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</li>
          <li>
            煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
          </li>
          <li>宣扬邪教、迷信的；</li>
          <li>扰乱社会秩序，破坏社会稳定的；</li>
          <li>诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</li>
          <li>
            侮辱或者诽谤他人，侵害公民个人名誉、隐私、知识产权和其他合法权益
            的；
          </li>
          <li>危害社会公德，损害民族优秀文化传统的；</li>
          <li>有关法律、行政法规和国家规定禁止的其他内容。</li>
        </ol>
      </li>
      <li>
        如果您上传、发布或传输的信息、内容含有以上违反法律法规的信息或内容，或者侵犯任何第三方的合法权益，您将直接承担以上导致的一切不利后果。如因此给语有灵犀造成不利后果的，您应负责消除影响，并且赔偿语有灵犀因此导致的一切损失，包括且不限于财产损害赔偿、名誉损害赔偿、律师费、交通费等因维权而产生的合理费用。
      </li>
      <li>
        您同意，遵守法律及本协议规定，秉承善意使用语有灵犀，且保证：
        <ol>
          <li>不会以非法方式获取或利用语有灵犀其他用户信息；</li>
          <li>
            不会以技术方式攻击、破坏或改变语有灵犀的部分或全部服务或干扰其运
            行；
          </li>
          <li>
            不会对语有灵犀运行的任何程序进行反向工程、反向编译、反向汇编或改
            写；
          </li>
          <li>
            不会未经许可使用语有灵犀的网站名称、公司名称、商标、网页版式或内容、或其广州学晟科技有限公司（及关联方）或语有灵犀享有知识产权或权利的信息及资料；
          </li>
          <li>
            不以商业目的使用语有灵犀，不对语有灵犀的任何部分或全部进行商业性质的利用、复制、拷贝、出售、调查、广告，不将语有灵犀用于其他任何商业目的或商业性质的活动，但语有灵犀与您另行签订有协议或语有灵犀另行指定的可供您使用的特定商业服务除外。
          </li>
        </ol>
      </li>
      <p>
        如您违反上述约定，语有灵犀可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门或司法机关。
      </p>
      <li>
        针对某些特定的语有灵犀提供的服务的使用规则及说明，语有灵犀将通过各种方式（包括但不限于网页公告、系统通知、私信、短信提醒等）做出任何声明、通知、警示等内容视为本协议的一部分，
        <strong>
          <u>如您使用该等服务，视为您同意该等声明、通知、警示内容。</u>
        </strong>
      </li>
    </ol>
    <h2>五、账号的注销</h2>
    <ol>
      <li>
        当您完成全部注销程序后，即表示您已充分阅读、理解并接受账号注销条款中的全部内容。
        <strong>
          <u>
            如您对本条款中的任一条件或约定有异议，请您立即停止账号注销程序。
          </u>
        </strong>
      </li>
      <li>
        您在确认注销语有灵犀账号后，将无法再使用本语有灵犀账号，也将无法找回语有灵犀语有灵犀账号中及账号相关的任何内容或信息，语有灵犀亦无法协助您重新恢复该等服务且不会做出任何赔偿。该等服务内容或信息包括但不限于：
        <ol>
          <li>账号信息、虚拟资产、会员权益、已购产品；</li>
          <li>与第三方绑定或授权的关系；</li>
          <li>
            本语有灵犀账号的个人资料和历史信息（包括但不限于用户名、用户头像、购物记录、关注信息、学习数据、证书等）；
          </li>
          <li>您曾获得的优惠券、积分、资格、订单及其他卡券等。</li>
        </ol>
      </li>
      <p>
        <strong>
          <u>
            您知晓并理解，根据相关法律规定，您的相关交易记录须在语有灵犀后台保存
          </u>
        </strong>
        <strong>
          <u>3</u>
        </strong>
        <strong>
          <u>年甚至更长的时间。</u>
        </strong>
      </p>
      <li>
        如果您执意要注销账号，您的账号需要同时满足以下条件：
        <ol>
          <li>
            在最近一个月内，账号没有进行更改密码、更改绑定信息等敏感操作；
          </li>
          <li>自愿放弃账号在语有灵犀系统中的资产和虚拟权益；</li>
          <li>账号内无未完成的订单和服务；</li>
          <li>
            账号不存在任何未决争议，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等。
          </li>
        </ol>
      </li>
      <li>
        在账号注销期间，如果您的语有灵犀账号涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，语有灵犀有权自行终止本语有灵犀账号的注销而无需另行获取您的同意。
      </li>
      <li>
        注销本语有灵犀账号并不代表本语有灵犀账号注销前的账号行为和相关责任获取豁免或减轻。
      </li>
    </ol>
    <h2>六、个人信息及隐私条款</h2>
    <ol>
      <li>
        语有灵犀收集、存储、使用、处理用户个人信息将遵守相关法律法规，收集、使用个人信息将遵循合法、正当、必要的原则。
      </li>
      <li>
        语有灵犀可能需要搜集能识别用户身份的个人信息以便可以在必要时联系用户，或为用户提供更好的使用体验。
        “个人信息”
        包括但不限于用户的姓名、性别、年龄、出生日期、联系电话、身份证号、地址、受教育情况、公司情况、所属行业、兴趣爱好、所处位置信息、个人说明、手机软硬件信息、手机识别码等。
      </li>
      <li>
        语有灵犀承诺，不以非法方式披露您依照语有灵犀规定的方式标明应予保密的用户个人信息。
      </li>
      <li>
        您了解并同意，除为提供或改进语有灵犀产品及服务合理所需以外，语有灵犀在下述情形下可利用您的个人信息：
        <ol>
          <li>在紧急情况下，为维护用户及公众的权益；</li>
          <li>
            为维护语有灵犀的著作权、商标权、专利权及其他任何合法权利或权益；
          </li>
          <li>
            在进行促销或抽奖时，语有灵犀可能会与赞助商共享用户的个人信息，在这些情况下语有灵犀会在发送用户信息之前进行提示，并且用户可以通过明确表示不参与活动而终止传送过程；
          </li>
          <li>为获取第三方数据而将用户信息与第三方数据匹配；</li>
          <li>
            将用户数据用于统计，以便向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述语有灵犀的服务。
          </li>
        </ol>
      </li>
    </ol>
    <h2>七、知识产权</h2>
    <ol>
      <li>
        广州学晟科技有限公司对语有灵犀相关产品和服务及语有灵犀所使用的软件所包含的受知识产权法或其他法律保护的对象享有相应的权利。语有灵犀产品及服务的整体内容及其任何部分（包括但不仅限于网站设计、程序、代码、设计图样以及其他图样、产品及服务名称）的版权、商标权及其他知识产权归语有灵犀享有，任何人不得使用、复制或用作其他用途。
      </li>
      <li>
        经由语有灵犀产品及服务传送的文字、图片、音频、视频及其他内容，受到著作权法、商标法、专利法或其他法律的保护；未经相关权利人书面授权许可，您不得自行或许可第三方进行使用、修改、出租、售卖、传播、衍生其他作品或进行任何其他使用或处分。
      </li>
      <li>
        语有灵犀对其专有内容、原创内容和其他通过授权取得的内容享有知识产权或相应权利。未经语有灵犀书面许可，任何单位和个人不得私自转载、传播和提供语有灵犀提供的服务或者实施任何其他侵犯语有灵犀知识产权的行为，否则，语有灵犀将依法追究相关法律责任。
      </li>
      <li>
        为了促进知识的分享和传播，您同意将在语有灵犀产品上发表的全部内容（包括但不仅限于留言、评论和笔记），授予语有灵犀免费的、不可撤销的、非独家使用许可权，语有灵犀有权将该内容用于语有灵犀各种形态的产品和服务上，包括但不限于语有灵犀已经或未来发布的应用或其他互联网产品。
      </li>
      <li>
        您在语有灵犀产品上传或发表的内容，应保证您为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，语有灵犀有权根据实际情况删除相关的内容，且有权追究用户的法律责任，给语有灵犀或任何第三方造成损失的，用户应负责全额赔偿。
      </li>
      <li>
        语有灵犀有权对您发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及语有灵犀公开的原则对侵权信息进行处理。
      </li>
    </ol>
    <h2>八、免责声明（以下内容请重点详细阅读）</h2>
    <ol>
      <li>
        语有灵犀按照语有灵犀产品及服务的“现状”
        向用户提供产品及服务，语有灵犀不对语有灵犀产品及/
        或其功能、服务作出任何其他明示或暗示的保证。对经由语有灵犀产品取得的任何产品、服务（含收费服务）或其他材料可能不符合您的期望；基于不同用户的个人特征和喜好，语有灵犀产品中可能存在一些让您不快、厌恶或难以接受的内容；语有灵犀不因前述情形向您承担任何责任。
      </li>
      <li>
        用户在语有灵犀上进行的任何行为均是用户的个人行为，由于信息的海量性，语有灵犀无法对信息或内容的权属、合法性、合规性、真实性、科学性、完整权、有效性等进行审查。
        <strong>
          <u>
            因用户行为或用户上传、存储、发布、传播的任何信息、内容等引发的任何争议、责任，或由此产生的任何直接、间接、偶然、特殊的损害，均由用户自行承担法律责任，语有灵犀不承担任何责任
          </u>
        </strong>
        ；如用户行为对语有灵犀造成损失或不良影响，语有灵犀保留追究相关用户法律责任的权利。语有灵犀对用户所发布信息的删除或储存失败不承担任何法律责任。
      </li>
      <li>
        <u>
          不论在何种情况下，语有灵犀均不对由于互联网连接故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，黑客攻击，互联网病毒，网络运营商技术调整，政府临时管制或任何其他语有灵犀不能合理控制的原因而造成的语有灵犀产品不能访问、产品及服务中断、信息及数据的延误、停滞或错误，不能提供或延迟提供服务而承担责任
        </u>
        。
      </li>
      <li>
        <u>
          不论是否可以预见，不论是源于何种形式的行为或不作为，语有灵犀不对因任何原因造成的任何特别的、间接的、惩罚性的、突发性的或其他任何损害（包括但不限于利润或其他可得利益的损失）承担责任。除语有灵犀因故意或重大过失损害用户的利益以外，任何情况下，语有灵犀向用户承担的赔偿、补偿或其他任何责任的限额将不超过该等用户就相关服务向语有灵犀支付的服务费用的数额
        </u>
        。
      </li>
      <li>
        <u>
          基于互联网的开放性属性，用户知悉用户将信息、内容等上传到互联网上，可能会被其他组织或个人复制、转载、修改或进行其他使用（包括用于非法用途），用户必须充分意识到此类风险的存在。用户同意使用语有灵犀产品过程中所存在的上述风险将完全由用户自行承担，语有灵犀对此不承担任何责任
        </u>
        。
      </li>
    </ol>
    <h2>九、服务中止、中断及终止</h2>
    <ol>
      <li>
        语有灵犀根据自身商业决策等原因可能会选择中止、中断及终止平台服务。如有此等情形发生，语有灵犀会采取公告的形式通知您。
      </li>
      <li>
        经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者语有灵犀根据自身判断，认为您的行为涉嫌违反本协议内容或语有灵犀不时公布的使用规则等内容，或涉嫌违反法律法规相关规定的，则语有灵犀有权中止、中断或终止向您提供服务，且无须为此向您或任何第三方承担责任。
      </li>
      <li>
        <u>
          您应了解并同意，在紧急情况或特殊下，语有灵犀可不经通知即中止或终止产品使用及服务。
        </u>
        在本协议或本协议项下语有灵犀提供的产品使用及服务（含收费服务）中止或终止时，语有灵犀有权：
        <ol>
          <li>拒绝用户登录语有灵犀产品及服务；</li>
          <li>删除用户信息；</li>
          <li>删除用户购买的内容。</li>
        </ol>
      </li>
      <li>
        <u>
          您同意，如因语有灵犀原因导致付费产品在到期前终止使用，语有灵犀仅需按照相关产品规则向您返还未到期部分对应的价款，语有灵犀无需承担其他责任或义务，除上述情形外，语有灵犀无需因本协议或本协议项下语有灵犀所提供的产品使用及服务中止或终止，而向您作出赔偿或补偿或承担任何其他责任。
        </u>
      </li>
    </ol>
    <h2>十、退款</h2>
    <ol>
      <li>
        <u>
          本课程的商品性质为数字化知识产品及视频音频产品，属于特殊商品，您在购买产品前需要审慎考虑，产品一经售出，没有法定或者约定事由，不予退款
        </u>
        。
      </li>
      <li>
        若您在支付成功7
        天内有法定或约定事由申请退款，退款金额根据对应实际学习情况进行计算，扣除已使用部分比例方可发起退款，已使用部分不能申请退款。视频、音频类产品一经购买恕不退款（如有）。
      </li>
      <li>
        如您购买本软件产品或服务后获得了语有灵犀的实物赠品，您在申请退款时需先退回该赠品，因退还赠品产生的相关费用由您自行承担。在语有灵犀对退还的赠品进行查验、确认退还赠品包装与寄送时完全一致且赠品无任何使用痕迹后，语有灵犀开始审批退款。若您选择不退还赠品或退还的赠品经查验后不符合前述退还条件，您可获得的退款金额将扣除该赠品价格。
      </li>
      <li>
        退款成功后，您在语有灵犀所获赠的积分、代金券、优惠券、船票等（如有）将会被注销，不能再次使用。
      </li>
      <li>
        退款审批完成后，退款金额将原路返还至您的支付账户（采用第三方分期方式付款的学员，退款金额将直接返还至第三方账户，且退款时间以第三方平台约定为准），且您的对应课程学习资格将会被注销，即不可再对课程进行查看和学习。
      </li>
    </ol>
    <h2>十一、法律适用</h2>
    <p>
      您在使用语有灵犀产品及服务时，应遵守中华人民共和国法律、及您所在地之法律、及您自愿或应当接收其管辖之法律。本协议的解释及履行应按照中华人民共和国（为本协议之目的，不包括港澳台地区）法律进行。
    </p>
    <h2>十二、争议解决</h2>
    <p>
      您和语有灵犀均同意，因本协议解释或执行引起的任何争议，双方应首先友好协商解决。协商不成时，则任何一方均可将争议提交至语有灵犀运营方所在地法院诉讼解决。
    </p>
    <h2>十三、其他约定</h2>
    <ol>
      <li>
        语有灵犀不行使、未能及时行驶或未充分行驶本协议内容或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响语有灵犀在将来使用该权利。
      </li>
      <li>
        本协议（包括任何规则）如果变更，语有灵犀将尽可能提前在语有灵犀产品中发布通知。但您应了解并同意，语有灵犀有可能未经事先通知，而修改或变更本协议的全部或部分内容，您也应随时关注本协议是否有修改或变更之情形。如果本协议修改或变更，而您不能接受修改或变更后的内容，您应立即停止使用语有灵犀相关产品和服务。如果您继续使用语有灵犀相关产品和服务，则表明您完全接受并愿意遵守修改或变更后的本协议。无论您停止使用或继续使用语有灵犀相关产品和服务，您都承诺不因本协议修改或变更而向语有灵犀要求赔偿或补偿或提出其他权利主张。
      </li>
      <li>
        您再次确认您已全部阅读并充分理解上述协议。如有任何疑问，可通过客服与语有灵犀取得联系。
      </li>
    </ol>
  </>
);
