export const zhHantContent = (
  <>
    <h1>《語有靈犀用戶服務協議》</h1>
    <p>
      歡迎使用廣州學晟科技有限公司提供的服務，語有靈犀用戶服務協議（以下簡稱
      “本協議”）是用戶（以下簡稱“您” 或“用戶” ）與廣州學晟科技有限公司
      及其關聯方（以下簡稱語有靈犀）關於用戶使用語有靈犀提供的服務所訂立的協議。
    </p>
    <h2>【特別提示】</h2>
    <ol>
      <li>
        請您仔細閱讀本協議全部條款，在注冊成爲會員前，請您仔細閱讀考試及題庫介紹頁麵及本協議全部條款，並確認您已經完全了解語有靈犀的教學大綱及本協議之規定。報名後。
      </li>
      <li>
        未成年人應當在監護人陪同和指導下閱讀本協議，並在使用本協議項下服務前取得監護人的同意。若您不具備前述與您行爲相適應的民事行爲能力，則您及您的監護人應依照法律規定承擔因此而導緻的一切後果。
      </li>
      <li>
        請您認真審閱並充分理解本協議各條款的內容，並選擇接受或不接受本協議，
        <strong>
          <u>
            特別是免除或者限製責任的條款、法律適用和爭議解決條款，您應當重點閱讀。您在語有靈犀進行注冊、登錄、使用等行爲將視爲對本協議的接受，並視爲您同意接受本協議的各項條款約束，本協議將構成您與語有靈犀之間直接有約束力的法律文件。
          </u>
        </strong>
      </li>
      <li>
        請您確認您依照中國法律法規及您所在地之法律及您自願或應當接收其管轄之法律，有權籤署並同意接受本協議的約束。如果您代表您的僱主或單位，您確認並保証您已獲得籤署本協議的充分授權。
      </li>
    </ol>
    <h2>一、服務須知</h2>
    <ol>
      <li>
        您使用“語有靈犀”軟件服務，可以通過預裝、公司授權的第三方下載等方式獲取客戶端應用程序或訪問語有靈犀相關網站。若您並非從公司或公司授權的第三方獲取“語有靈犀”軟件，公司不保証非官方版本的“語有靈犀”軟件可以正常使用，您因此遭受的損失與公司無關。
      </li>
      <li>
        公司爲不同終端設別開髮不同的應用程序軟件版本，您應當根據實際設別狀況獲取、下載、安裝合適的版本（如有）
      </li>
      <li>
        您可根據需要自行使用“語有靈犀” 軟件及相關服務或更新“語有靈犀”
        版本，如您不再需要使用“語有靈犀”
        軟件及相關服務，您也可自行卸載相應的應用程序軟件。
      </li>
      <li>
        除非得到公司明示事先書麵授權，您不得以任何形式對“語有靈犀”軟件及相關服務進行包括但不限於改編、複製、傳播、垂直蒐索、鏡像或交易等未經授權的訪問或使用。
      </li>
      <li>
        爲了改善用戶體驗，廣州學晟科技有限公司將不定時提供更新，爲了保証軟件和服務的安全性，以及功能上和安全上的一緻性，廣州學晟科技有限公司有權不經向您特別通知而對軟件進行更新
      </li>
    </ol>
    <h2>二、收費服務及説明</h2>
    <ol>
      <li>
        語有靈犀提供的部分服務爲收費服務，您使用收費服務時需要向語有靈犀支付一定的費用。對於收費的服務，語有靈犀會給您明確的提示，支付行爲的完成以銀行或第三方支付平颱生成
        “支付已完成”
        的確認通知爲準。隻有您根據提示確認其願意支付相關費用，您才能使用該收費服務。如您拒絶支付相關費用，則語有靈犀有權不向您提供該收費服務。
      </li>
      <li>
        您確認，您在完成語有靈犀收費服務的支付行爲後，將視爲已接受並開始使用語有靈犀的收費服務，
        <strong>
          <u>
            因此您在語有靈犀已購買的任何收費或增值服務不能以任何理由退購（即退換成法定貨幣）或調換成其他服務
          </u>
        </strong>
        。
      </li>
      <li>
        您理解，語有靈犀僅提供與語有靈犀服務相關的技術服務，除此之外與相關網絡服務有關的設備（如個人電腦、手機、及其他與接入互聯網和移動網有關的裝置）及所需的費用（如爲接入互聯網而支付的電話費以及上網費、爲使用移動網絡而支付的手機費等）均由您自行負擔。
      </li>
      <li>
        <u>
          因您自身原因導緻的語有靈犀無法在您購買收費服務後提供該收費服務而産生任何損失或責任的，由您自行負責，語有靈犀不承擔責任，包括但不限於：
        </u>
        <ol>
          <li>
            <u>因您的賬戶丟失、被封禁或凍結；</u>
          </li>
          <li>
            <u>您將密碼告知他人導緻的財産損失；</u>
          </li>
          <li>
            <u>因您綁定的第三方支付機構賬戶的原因導緻的任何損失或責任；</u>
          </li>
          <li>
            <u>其他用戶故意或者重大過失或違反法律導緻的財産損失。</u>
          </li>
        </ol>
      </li>
      <li>
        若因語有靈犀支付繫統自身故障原因造成您付款失實，在平颱恢複、存有有效數據和您提供合法有效憑証的情況下，語有靈犀將根據您的付款情況作出變動措施：
        <ol>
          <li>
            因充值繫統自身故障原因，造成産品金額小於您實際付款金額的，平颱予以補其差額；
          </li>
          <li>
            因充值繫統自身故障原因，造成産品金額大於您實際付款金額的，平颱有權追回差額。
          </li>
        </ol>
      </li>
      <li>
        本課程支持您採用第三方渠道（如銀行、花唄）分期方式付款，語有靈犀僅爲您提供第三方支付通道的便利。如您採用分期方式付款的，應當切實考慮自身經濟情況及與該第三方單獨籤署相關協議，並根據該協議的約定自行承擔還款責任及逾期還款的違約責任。如因第三方分期學員退款給語有靈犀造成利息損失的，語有靈犀有權在應退款項中予以扣除。
      </li>
      <li>
        您付款成功後，可在付款後15
        日內向語有靈犀申請開具增值稅髮票，語有靈犀將於收到申請後
        15個工作日內開具增值稅髮票。
      </li>
    </ol>
    <h2>三、賬號注冊</h2>
    <ol>
      <li>
        您需要在語有靈犀産品中注冊一個賬號，並按照語有靈犀産品公示的要求進行賬號設置，才可以使用該賬號登錄並使用語有靈犀相關産品和服務。語有靈犀將在確認收到全部應收費用後按您購買的考試産品一次性交付並獲得相應考試試題及資訊服務，您可根據自身需求安排學習。
      </li>
      <li>
        您在注冊語有靈犀賬號時，應按照語有靈犀産品公示的提示及要求填冩或提供資料、信息，並確保用戶身份及信息的真實性、正確性及完整性，且保証填冩、登錄、使用的賬號名稱、頭像、個人簡介等賬號信息資料應遵守法律法規、社會主義製度、國家利益、公民合法權益、公共秩序、社會道德風尚和信息真實性等七條底線，不得在賬號信息中出現任何不良信息；如果資料髮生變化，您應及時更改。如您違反上述注冊規定，因此導緻的法律後果、損失、責任將由您自行承擔。
      </li>
      <li>
        語有靈犀賬號的注冊可使用第三方平颱賬號的關聯授權，在經過您的同意並確認後，我們將獲取您的帶三方賬號名稱、頭像等信息進行注冊，您應當確保此關聯授權的第三方賬號信息符合本協議【
        3.2 條款】之規定，否則導緻的法律後果、損失及責任將由您自行承擔。
      </li>
    </ol>
    <h2>四、賬號的使用</h2>
    <ol>
      <li>
        語有靈犀賬號的所有權歸廣州學晟科技有限公司所有，您完成申請注冊手續後，獲得語有靈犀賬號的使用權，該使用權僅屬於初始注冊人，禁止贈與、藉用、租用、轉讓或者售賣。語有靈犀因經營需要，有權收回您的賬號。
      </li>
      <li>
        您按照語有靈犀的要求完成注冊後，將得到一個賬號和密碼（如有）。您應妥善保管該賬號及密碼（如有），並對該賬號進行的所有活動及事件負法律責任。因黑客行爲或您保管疏忽緻使賬號、密碼（如有）被他人非法使用或佔有而造成經濟損失的，語有靈犀不承擔任何責任。
      </li>
      <li>
        您應了解，在賬號和密碼匹配時，語有靈犀無法對非法或未經您授權使用您賬號及密碼的行爲作出甄別，因此，語有靈犀對任何使用您賬號和密碼登錄並使用語有靈犀産品及服務的行爲不承擔任何責任。
      </li>
      <li>
        語有靈犀有權根據自己的判定，在懷疑賬號被不當使用時，拒絶或限製賬號使用，或注銷該賬號，同時終止本協議。除有使用期限的付費服務以外，如您連續
        180
        天未以賬號登錄語有靈犀産品，則語有靈犀有權根據自己的判定，注銷該賬號且無需事先向您髮送通知。
      </li>
      <li>
        您不得利用語有靈犀服務從事以下任一情形的事物或活動：
        <ol>
          <li>反對憲法確定的基本原則的；</li>
          <li>危害國家統一、主權和領土完整的；</li>
          <li>洩露國家秘密、危害國家安全或者損害國家榮譽和利益的；</li>
          <li>
            煽動民族仇恨、民族歧視，破壞民族團結，或者侵害民族風俗、習慣的；
          </li>
          <li>宣揚邪教、迷信的；</li>
          <li>擾亂社會秩序，破壞社會穩定的；</li>
          <li>誘導未成年人違法犯罪和渲染暴力、色情、賭博、恐怖活動的；</li>
          <li>
            侮辱或者誹謗他人，侵害公民個人名譽、隱私、知識産權和其他合法權益
            的；
          </li>
          <li>危害社會公德，損害民族優秀文化傳統的；</li>
          <li>有關法律、行政法規和國家規定禁止的其他內容。</li>
        </ol>
      </li>
      <li>
        如果您上傳、髮佈或傳輸的信息、內容含有以上違反法律法規的信息或內容，或者侵犯任何第三方的合法權益，您將直接承擔以上導緻的一切不利後果。如因此給語有靈犀造成不利後果的，您應負責消除影響，並且賠償語有靈犀因此導緻的一切損失，包括且不限於財産損害賠償、名譽損害賠償、律師費、交通費等因維權而産生的合理費用。
      </li>
      <li>
        您同意，遵守法律及本協議規定，秉承善意使用語有靈犀，且保証：
        <ol>
          <li>不會以非法方式獲取或利用語有靈犀其他用戶信息；</li>
          <li>
            不會以技術方式攻擊、破壞或改變語有靈犀的部分或全部服務或幹擾其運
            行；
          </li>
          <li>
            不會對語有靈犀運行的任何程序進行反向工程、反向編譯、反向匯編或改
            冩；
          </li>
          <li>
            不會未經許可使用語有靈犀的網站名稱、公司名稱、商標、網頁版式或內容、或其廣州學晟科技有限公司（及關聯方）或語有靈犀享有知識産權或權利的信息及資料；
          </li>
          <li>
            不以商業目的使用語有靈犀，不對語有靈犀的任何部分或全部進行商業性質的利用、複製、拷貝、出售、調查、廣告，不將語有靈犀用於其他任何商業目的或商業性質的活動，但語有靈犀與您另行籤訂有協議或語有靈犀另行指定的可供您使用的特定商業服務除外。
          </li>
        </ol>
      </li>
      <p>
        如您違反上述約定，語有靈犀可能將您違反法律或侵犯第三方權利或權益的記錄報告給行政主管部門或司法機關。
      </p>
      <li>
        針對某些特定的語有靈犀提供的服務的使用規則及説明，語有靈犀將通過各種方式（包括但不限於網頁公告、繫統通知、私信、短信提醒等）做出任何聲明、通知、警示等內容視爲本協議的一部分，
        <strong>
          <u>如您使用該等服務，視爲您同意該等聲明、通知、警示內容。</u>
        </strong>
      </li>
    </ol>
    <h2>五、賬號的注銷</h2>
    <ol>
      <li>
        當您完成全部注銷程序後，即表示您已充分閱讀、理解並接受賬號注銷條款中的全部內容。
        <strong>
          <u>
            如您對本條款中的任一條件或約定有異議，請您立即停止賬號注銷程序。
          </u>
        </strong>
      </li>
      <li>
        您在確認注銷語有靈犀賬號後，將無法再使用本語有靈犀賬號，也將無法找回語有靈犀語有靈犀賬號中及賬號相關的任何內容或信息，語有靈犀亦無法協助您重新恢複該等服務且不會做出任何賠償。該等服務內容或信息包括但不限於：
        <ol>
          <li>賬號信息、虛擬資産、會員權益、已購産品；</li>
          <li>與第三方綁定或授權的關繫；</li>
          <li>
            本語有靈犀賬號的個人資料和曆史信息（包括但不限於用戶名、用戶頭像、購物記錄、關注信息、學習數據、証書等）；
          </li>
          <li>您曾獲得的優惠券、積分、資格、訂單及其他卡券等。</li>
        </ol>
      </li>
      <p>
        <strong>
          <u>
            您知曉並理解，根據相關法律規定，您的相關交易記錄須在語有靈犀後颱保存
          </u>
        </strong>
        <strong>
          <u>3</u>
        </strong>
        <strong>
          <u>年甚至更長的時間。</u>
        </strong>
      </p>
      <li>
        如果您執意要注銷賬號，您的賬號需要同時滿足以下條件：
        <ol>
          <li>
            在最近一個月內，賬號沒有進行更改密碼、更改綁定信息等敏感操作；
          </li>
          <li>自願放棄賬號在語有靈犀繫統中的資産和虛擬權益；</li>
          <li>賬號內無未完成的訂單和服務；</li>
          <li>
            賬號不存在任何未決爭議，包括但不限於投訴、舉報、訴訟、仲裁、國家有權機關調查等。
          </li>
        </ol>
      </li>
      <li>
        在賬號注銷期間，如果您的語有靈犀賬號涉及爭議糾紛，包括但不限於投訴、舉報、訴訟、仲裁、國家有權機關調查等，語有靈犀有權自行終止本語有靈犀賬號的注銷而無需另行獲取您的同意。
      </li>
      <li>
        注銷本語有靈犀賬號並不代表本語有靈犀賬號注銷前的賬號行爲和相關責任獲取豁免或減輕。
      </li>
    </ol>
    <h2>六、個人信息及隱私條款</h2>
    <ol>
      <li>
        語有靈犀收集、存儲、使用、處理用戶個人信息將遵守相關法律法規，收集、使用個人信息將遵循合法、正當、必要的原則。
      </li>
      <li>
        語有靈犀可能需要蒐集能識別用戶身份的個人信息以便可以在必要時聯繫用戶，或爲用戶提供更好的使用體驗。
        “個人信息”
        包括但不限於用戶的姓名、性別、年齡、出生日期、聯繫電話、身份証號、地址、受教育情況、公司情況、所屬行業、興趣愛好、所處位置信息、個人説明、手機軟硬件信息、手機識別碼等。
      </li>
      <li>
        語有靈犀承諾，不以非法方式披露您依照語有靈犀規定的方式標明應予保密的用戶個人信息。
      </li>
      <li>
        您了解並同意，除爲提供或改進語有靈犀産品及服務合理所需以外，語有靈犀在下述情形下可利用您的個人信息：
        <ol>
          <li>在緊急情況下，爲維護用戶及公衆的權益；</li>
          <li>
            爲維護語有靈犀的著作權、商標權、專利權及其他任何合法權利或權益；
          </li>
          <li>
            在進行促銷或抽獎時，語有靈犀可能會與讚助商共享用戶的個人信息，在這些情況下語有靈犀會在髮送用戶信息之前進行提示，並且用戶可以通過明確表示不參與活動而終止傳送過程；
          </li>
          <li>爲獲取第三方數據而將用戶信息與第三方數據匹配；</li>
          <li>
            將用戶數據用於統計，以便向未來的合作夥伴、廣告商及其他第三方以及爲了其他合法目的而描述語有靈犀的服務。
          </li>
        </ol>
      </li>
    </ol>
    <h2>七、知識産權</h2>
    <ol>
      <li>
        廣州學晟科技有限公司對語有靈犀相關産品和服務及語有靈犀所使用的軟件所包含的受知識産權法或其他法律保護的對象享有相應的權利。語有靈犀産品及服務的整體內容及其任何部分（包括但不僅限於網站設計、程序、代碼、設計圖樣以及其他圖樣、産品及服務名稱）的版權、商標權及其他知識産權歸語有靈犀享有，任何人不得使用、複製或用作其他用途。
      </li>
      <li>
        經由語有靈犀産品及服務傳送的文字、圖片、音頻、視頻及其他內容，受到著作權法、商標法、專利法或其他法律的保護；未經相關權利人書麵授權許可，您不得自行或許可第三方進行使用、修改、出租、售賣、傳播、衍生其他作品或進行任何其他使用或處分。
      </li>
      <li>
        語有靈犀對其專有內容、原創內容和其他通過授權取得的內容享有知識産權或相應權利。未經語有靈犀書麵許可，任何單位和個人不得私自轉載、傳播和提供語有靈犀提供的服務或者實施任何其他侵犯語有靈犀知識産權的行爲，否則，語有靈犀將依法追究相關法律責任。
      </li>
      <li>
        爲了促進知識的分享和傳播，您同意將在語有靈犀産品上髮表的全部內容（包括但不僅限於留言、評論和筆記），授予語有靈犀免費的、不可撤銷的、非獨家使用許可權，語有靈犀有權將該內容用於語有靈犀各種形態的産品和服務上，包括但不限於語有靈犀已經或未來髮佈的應用或其他互聯網産品。
      </li>
      <li>
        您在語有靈犀産品上傳或髮表的內容，應保証您爲著作權人或已取得合法授權，並且該內容不會侵犯任何第三方的合法權益。如果第三方提出關於著作權的異議，語有靈犀有權根據實際情況刪除相關的內容，且有權追究用戶的法律責任，給語有靈犀或任何第三方造成損失的，用戶應負責全額賠償。
      </li>
      <li>
        語有靈犀有權對您髮佈的內容進行審核，有權根據相關証據結合《侵權責任法》、《信息網絡傳播權保護條例》等法律法規及語有靈犀公開的原則對侵權信息進行處理。
      </li>
    </ol>
    <h2>八、免責聲明（以下內容請重點詳細閱讀）</h2>
    <ol>
      <li>
        語有靈犀按照語有靈犀産品及服務的“現狀”
        向用戶提供産品及服務，語有靈犀不對語有靈犀産品及/
        或其功能、服務作出任何其他明示或暗示的保証。對經由語有靈犀産品取得的任何産品、服務（含收費服務）或其他材料可能不符合您的期望；基於不同用戶的個人特徵和喜好，語有靈犀産品中可能存在一些讓您不快、厭噁或難以接受的內容；語有靈犀不因前述情形向您承擔任何責任。
      </li>
      <li>
        用戶在語有靈犀上進行的任何行爲均是用戶的個人行爲，由於信息的海量性，語有靈犀無法對信息或內容的權屬、合法性、合規性、真實性、科學性、完整權、有效性等進行審查。
        <strong>
          <u>
            因用戶行爲或用戶上傳、存儲、髮佈、傳播的任何信息、內容等引髮的任何爭議、責任，或由此産生的任何直接、間接、偶然、特殊的損害，均由用戶自行承擔法律責任，語有靈犀不承擔任何責任
          </u>
        </strong>
        ；如用戶行爲對語有靈犀造成損失或不良影響，語有靈犀保留追究相關用戶法律責任的權利。語有靈犀對用戶所髮佈信息的刪除或儲存失敗不承擔任何法律責任。
      </li>
      <li>
        <u>
          不論在何種情況下，語有靈犀均不對由於互聯網連接故障，電力故障，罷工，勞動爭議，暴亂，起義，騷亂，火災，洪水，風暴，爆炸，不可抗力，戰爭，政府行爲，國際、國內法院的命令，黑客攻擊，互聯網病毒，網絡運營商技術調整，政府臨時管製或任何其他語有靈犀不能合理控製的原因而造成的語有靈犀産品不能訪問、産品及服務中斷、信息及數據的延誤、停滯或錯誤，不能提供或延遲提供服務而承擔責任
        </u>
        。
      </li>
      <li>
        <u>
          不論是否可以預見，不論是源於何種形式的行爲或不作爲，語有靈犀不對因任何原因造成的任何特別的、間接的、懲罰性的、突髮性的或其他任何損害（包括但不限於利潤或其他可得利益的損失）承擔責任。除語有靈犀因故意或重大過失損害用戶的利益以外，任何情況下，語有靈犀向用戶承擔的賠償、補償或其他任何責任的限額將不超過該等用戶就相關服務向語有靈犀支付的服務費用的數額
        </u>
        。
      </li>
      <li>
        <u>
          基於互聯網的開放性屬性，用戶知悉用戶將信息、內容等上傳到互聯網上，可能會被其他組織或個人複製、轉載、修改或進行其他使用（包括用於非法用途），用戶必須充分意識到此類風險的存在。用戶同意使用語有靈犀産品過程中所存在的上述風險將完全由用戶自行承擔，語有靈犀對此不承擔任何責任
        </u>
        。
      </li>
    </ol>
    <h2>九、服務中止、中斷及終止</h2>
    <ol>
      <li>
        語有靈犀根據自身商業決策等原因可能會選擇中止、中斷及終止平颱服務。如有此等情形髮生，語有靈犀會採取公告的形式通知您。
      </li>
      <li>
        經國家行政或司法機關的生效法律文書確認您存在違法或侵權行爲，或者語有靈犀根據自身判斷，認爲您的行爲涉嫌違反本協議內容或語有靈犀不時公佈的使用規則等內容，或涉嫌違反法律法規相關規定的，則語有靈犀有權中止、中斷或終止向您提供服務，且無須爲此向您或任何第三方承擔責任。
      </li>
      <li>
        <u>
          您應了解並同意，在緊急情況或特殊下，語有靈犀可不經通知即中止或終止産品使用及服務。
        </u>
        在本協議或本協議項下語有靈犀提供的産品使用及服務（含收費服務）中止或終止時，語有靈犀有權：
        <ol>
          <li>拒絶用戶登錄語有靈犀産品及服務；</li>
          <li>刪除用戶信息；</li>
          <li>刪除用戶購買的內容。</li>
        </ol>
      </li>
      <li>
        <u>
          您同意，如因語有靈犀原因導緻付費産品在到期前終止使用，語有靈犀僅需按照相關産品規則向您返還未到期部分對應的價款，語有靈犀無需承擔其他責任或義務，除上述情形外，語有靈犀無需因本協議或本協議項下語有靈犀所提供的産品使用及服務中止或終止，而向您作出賠償或補償或承擔任何其他責任。
        </u>
      </li>
    </ol>
    <h2>十、退款</h2>
    <ol>
      <li>
        <u>
          本課程的商品性質爲數字化知識産品及視頻音頻産品，屬於特殊商品，您在購買産品前需要審慎考慮，産品一經售出，沒有法定或者約定事由，不予退款
        </u>
        。
      </li>
      <li>
        若您在支付成功7
        天內有法定或約定事由申請退款，退款金額根據對應實際學習情況進行計算，扣除已使用部分比例方可髮起退款，已使用部分不能申請退款。視頻、音頻類産品一經購買恕不退款（如有）。
      </li>
      <li>
        如您購買本軟件産品或服務後獲得了語有靈犀的實物贈品，您在申請退款時需先退回該贈品，因退還贈品産生的相關費用由您自行承擔。在語有靈犀對退還的贈品進行查驗、確認退還贈品包裝與寄送時完全一緻且贈品無任何使用痕跡後，語有靈犀開始審批退款。若您選擇不退還贈品或退還的贈品經查驗後不符合前述退還條件，您可獲得的退款金額將扣除該贈品價格。
      </li>
      <li>
        退款成功後，您在語有靈犀所獲贈的積分、代金券、優惠券、船票等（如有）將會被注銷，不能再次使用。
      </li>
      <li>
        退款審批完成後，退款金額將原路返還至您的支付賬戶（採用第三方分期方式付款的學員，退款金額將直接返還至第三方賬戶，且退款時間以第三方平颱約定爲準），且您的對應課程學習資格將會被注銷，即不可再對課程進行查看和學習。
      </li>
    </ol>
    <h2>十一、法律適用</h2>
    <p>
      您在使用語有靈犀産品及服務時，應遵守中華人民共和國法律、及您所在地之法律、及您自願或應當接收其管轄之法律。本協議的解釋及履行應按照中華人民共和國（爲本協議之目的，不包括港澳颱地區）法律進行。
    </p>
    <h2>十二、爭議解決</h2>
    <p>
      您和語有靈犀均同意，因本協議解釋或執行引起的任何爭議，雙方應首先友好協商解決。協商不成時，則任何一方均可將爭議提交至語有靈犀運營方所在地法院訴訟解決。
    </p>
    <h2>十三、其他約定</h2>
    <ol>
      <li>
        語有靈犀不行使、未能及時行駛或未充分行駛本協議內容或者按照法律規定所享有的權利，不應被視爲放棄該權利，也不影響語有靈犀在將來使用該權利。
      </li>
      <li>
        本協議（包括任何規則）如果變更，語有靈犀將儘可能提前在語有靈犀産品中髮佈通知。但您應了解並同意，語有靈犀有可能未經事先通知，而修改或變更本協議的全部或部分內容，您也應隨時關注本協議是否有修改或變更之情形。如果本協議修改或變更，而您不能接受修改或變更後的內容，您應立即停止使用語有靈犀相關産品和服務。如果您繼續使用語有靈犀相關産品和服務，則表明您完全接受並願意遵守修改或變更後的本協議。無論您停止使用或繼續使用語有靈犀相關産品和服務，您都承諾不因本協議修改或變更而向語有靈犀要求賠償或補償或提出其他權利主張。
      </li>
      <li>
        您再次確認您已全部閱讀並充分理解上述協議。如有任何疑問，可通過客服與語有靈犀取得聯繫。
      </li>
    </ol>
  </>
);
